import * as React from "react";
import type { SVGProps } from "react";
const RevenueIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      stroke="#28303F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.333 3.333v29.334a4 4 0 0 0 4 4h29.334"
    />
    <path
      stroke="#28303F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m11.667 23.333 5.252-5.252a2 2 0 0 1 2.828 0l3.839 3.838a2 2 0 0 0 2.828 0l5.253-5.252"
    />
    <path
      stroke="#28303F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M29.167 15h3.166a1 1 0 0 1 1 1v3.167"
    />
  </svg>
);
export default RevenueIcon;