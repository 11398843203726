import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";

const onlineData = [0, 0, 0, 0, 0, 0, 0];
const takeData = [0, 0, 0, 0, 0, 0, 0];
const cashData = [0, 0, 0, 0, 0, 0, 0];
const cardData = [0, 0, 0, 0, 0, 0, 0];

const xLabels = [
  "Pazartesi",
  "Salı",
  "Çarşamba",
  "Perşembe",
  "Cuma",
  "Cumartesi",
  "Pazar",
];

export default function RevenueTypeChart() {
  return (
    <Box sx={{ width: "100%" }}>
      <BarChart
        colors={["#533483", "#FF0100", "#0F3460", "#367E18"]}
        width={1200}
        height={400}
        slotProps={{
          legend: {
            position: { vertical: "top", horizontal: "left" },
            labelStyle: { fontSize: 14, fontWeight: "600", color: "#3E4954" },
          },
        }}
        series={[
          { data: takeData, label: "Gel-Al", id: "take" },
          { data: onlineData, label: "Online Ödeme", id: "online" },
          { data: cashData, label: "Nakit", id: "cash" },
          { data: cardData, label: "Kapıda Kredi Kartı", id: "card" },
        ]}
        xAxis={[{ data: xLabels, scaleType: "band" }]}
      />
    </Box>
  );
}
