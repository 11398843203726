import CardHeadingText from '../../../components/Text/CardHeadingText';
import HeadingText from '../../../components/Text/HeadingText';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  MenuItem,
  Tooltip,
  Modal,
  Box,
  FormGroup,
  Checkbox,
} from '@mui/material';
import React, {useEffect, useState, useRef} from 'react';
import {FaImage, FaInfo, FaRegEdit, FaTrash, FaTrashAlt} from 'react-icons/fa';
import AuthLayout from '../../../layout/AuthLayout';
import {FaPen, FaX} from 'react-icons/fa6';
import MainStore from '../../../store/MainStore';
import axios from 'axios';
import toast from 'react-hot-toast';
import {HiOutlineTrash} from 'react-icons/hi2';
type OptionalDataProps = {
  _id: string;
  name: string;
  active: boolean;
  rId: string;
  total: number;
  optionalID?: string;
};

type TemplateOptionalProps = {
  _id?: string;
  name: string;
  price: number | string;
  optionalID?: string;
  active: boolean;
};

type TemplateProps = {
  _id: string;
  title: string;
  value: TemplateOptionalProps[];
  rId: string;
  active: boolean;
};

type SelectableValueProps = {
  name: string;
  price: number;
  optionalID: string;
  active: boolean;
};

type RequiredItem = {
  name: string;
  value: TemplateOptionalProps[];
  templateID?: string;
  active?: boolean;
  rId: string;
  selected: number;
};

type MultiSelectProps = {
  name: string;
  selectType: string;
  value: TemplateOptionalProps[];
  active: boolean;
  templateID?: string;
  rId: string;
};

type SelectableProps = {
  required: RequiredItem[];
  optional?: RequiredItem[];
  promotion?: RequiredItem[];
};

type RemoveableProps = {
  name: string;
};

type CategoryListProps = {
  name: string;
};

function Page() {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<string | number>('');
  const [discountType, setDiscountType] = useState<string>('none');
  const [discount, setdiscount] = useState<string | number>('');
  const [category, setCategory] = useState<string>('none');
  const [subCategory, setSubCategory] = useState<string>('none');
  const [subCategoryList, setSubCategoryList] = useState<string[]>([]);
  const [subCategoryModal, setSubCategoryModal] = useState<boolean>(false);
  const [subCategoryInput, setSubCategoryInput] = useState<string>('');
  const [removeable, setRemoveable] = useState<RemoveableProps[] | []>([]);
  const [removeableInput, setRemoveableInput] = useState<string>('');
  const [removeableModal, setRemoveableModal] = useState<boolean>(false);
  const [multiSelect, setMultiSelect] = useState<MultiSelectProps[] | []>([]);
  const [foodImage, setFoodImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [optionalData, setOptionalData] = useState<OptionalDataProps[] | null>(null);
  const [templatesData, setTemplatesData] = useState<TemplateProps[] | null>(null);
  const [selectableModal, setSelectableModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'sablonlar' | 'opsiyonlar'>('sablonlar');
  const [modalSelection, setModalSelection] = useState<'required' | 'optional' | 'promotion' | 'multiselect'>(
    'required'
  );
  const [selectable, setSelectable] = useState<SelectableProps>({
    required: [],
    optional: [],
    promotion: [],
  });
  const [step, setStep] = useState<number>(1);
  const [tempTemplates, setTempTemplates] = useState({});
  const [templateName, setTemplateName] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [templatesValue, setTemplatesValues] = useState<TemplateOptionalProps[] | []>([]);
  const [selectType, setSelectType] = useState<'required' | 'optional'>('required');
  const [editedIndex, setEditedIndex] = useState<number>(-1);
  const [categoryList, setCategoryList] = useState<CategoryListProps[] | []>([]);

  function replaceCommasWithDot(input: string) {
    if (typeof input !== 'string') {
      throw new Error('Input must be a string');
    }
    const replacedString = input.replace(/,/g, '.');
    return replacedString;
  }

  useEffect(() => {
    axios
      .get('https://api.yemek.club/restaurant/api/getMyInfo', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setSubCategoryList(res.data.subCategory);
      });

    axios
      .get('https://api.yemek.club/restaurant/api/getCategory', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        console.log(res.data);
        setCategoryList(res.data);
      });

    axios
      .get('https://api.yemek.club/restaurant/api/getTemplates', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setTemplatesData(res.data);
      });

    axios
      .get('https://api.yemek.club/restaurant/api/getMyOptional', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setOptionalData(res.data);
      });
  }, []);

  const addSubCategory = async () => {
    const subCategoryArray = [...subCategoryList];
    subCategoryArray.push(subCategoryInput);
    axios
      .post(
        'https://api.yemek.club/restaurant/api/editMyRestaurant',
        {subCategory: subCategoryArray},
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        if (res.data.success) {
          toast.success('Menü kategorisi başarıyla eklendi !');
          setSubCategoryInput('');
          setSubCategoryModal(false);
          setSubCategoryList(subCategoryArray);
        } else {
          toast.error('Bir sorun oluştu, lütfen daha sonra tekrar deneyiniz.');
        }
      });
  };

  const checkChecked = (option: OptionalDataProps) => {
    const validate = templatesValue?.find((item) => item.optionalID == option._id);
    if (validate) {
      return true;
    } else {
      return false;
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const createFood = async () => {
    if (!name) {
      toast.error('Lütfen ürün adı alanını doldurunuz.');
    } else if (!price) {
      toast.error('Lütfen fiyat alanını doldurunuz');
    } else if (!category || category == 'none') {
      toast.error('Lütfen kategori alanını doldurunuz');
    } else if (!subCategory) {
      toast.error('Lütfen menü kategorisi alanını doldurunuz');
    } else {
      const formData = new FormData();
      const allData = JSON.stringify({
        name,
        description,
        price,
        discountType,
        discount,
        category,
        subCategory,
        removeable,
        multiSelect,
        selectable,
      });
      if (foodImage) {
        formData.append('foodImage', foodImage);
      }
      formData.append('data', allData);

      axios
        .post('https://api.yemek.club/restaurant/api/addFood', formData, {
          headers: {
            Authorization: `Bearer ${MainStore.token}`,
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.data.name) {
            setName('');
            setDescription('');
            setPrice('');
            setDiscountType('none');
            setdiscount('');
            setCategory('none');
            setSubCategory('none');
            setSubCategoryInput('');
            setSubCategoryModal(false);
            setRemoveable([]);
            setMultiSelect([]);
            setFoodImage(null);
            setImageUrl(null);
            setSelectable({required: [], optional: [], promotion: []});
            toast.success('Yemeğiniz başarıyla kaydedilmiştir.');
          }
        });
    }
  };

  return (
    <AuthLayout>
      <div>
        <div className='mt-8 '>
          <HeadingText
            title='Yemek Ekle'
            subTitle={'Menünüze yeni yemek ekleyebilirsiniz !'}
          />
        </div>

        <div className='flex flex-col lg:flex-row  justify-between gap-x-2'>
          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <CardHeadingText
              title={'Yemek Bilgisi'}
              subTitle={'Yemeğinize ait görsel isim ve açıklama bilgilerini ekleyiniz.'}
            />

            <div
              onClick={() => inputRef?.current?.click()}
              className='flex flex-col lg:flex-row gap-y-2 mt-4'
            >
              {imageUrl ? (
                <>
                  <img
                    src={imageUrl}
                    className='w-[100px] h-[100px] rounded-md cursor-pointer'
                  />
                </>
              ) : (
                <>
                  <div className='bg-[#F9F9F9] min-w-[100px] flex flex-col justify-center items-center  p-4 border-[1px] rounded-md cursor-pointer'>
                    <FaImage className='text-[21px] text-gray-500' />
                    <p className='text-gray-500 text-[13px] mt-1'>Görsel Yükle</p>
                  </div>
                  <div className=' font-semiBold text-gray-700 ml-4 self-center text-[14px]'>
                    <p>Ürününüze ait bir görsel ekleyerek satışlarınızı arttırabilirsiniz yardımcı olur.</p>
                    <p>Yükleyeceğiniz dosya Fotoğraf(jpg,png,webp) olmak zorundadır maksimum 10MB yükleyebilirsiniz</p>
                  </div>
                </>
              )}
              <input
                ref={inputRef}
                id='uploadInput'
                type='file'
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    setFoodImage(file);

                    const objectUrl = URL.createObjectURL(file);
                    setImageUrl(objectUrl);
                  }
                }}
                accept='image/jpeg, image/png, image/webp'
                style={{display: 'none'}}
              />
            </div>

            <div className='mt-4 flex flex-col lg:flex-row justify-between'>
              <FormControl
                fullWidth
                sx={{m: 1}}
                className='bg-[#F9F9F9] w-12/12 lg:w-6/12'
              >
                <InputLabel
                  className='font-semiBold'
                  htmlFor='yemek-adi'
                >
                  Yemek Adı
                </InputLabel>
                <OutlinedInput
                  id='yemek-adi'
                  label='Yemek Adı'
                  className='font-medium text-[17px]'
                  type='string'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl
                fullWidth
                sx={{m: 1}}
                className='bg-[#F9F9F9] w-12/12 lg:w-6/12'
              >
                <InputLabel
                  className='font-semiBold'
                  htmlFor='yemek-aciklamasi'
                >
                  Yemek Açıklaması
                </InputLabel>
                <OutlinedInput
                  id='yemek-aciklamasi'
                  label='Yemek Açıklaması'
                  className='font-medium text-[17px]'
                  type='string'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
            </div>
          </div>

          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <CardHeadingText
              title={'Yemek Detayı'}
              subTitle={'Yemeğinize ve menünüze özel kategorileri ekleyiniz.'}
            />

            <div className='mt-4'>
              <div className='flex'>
                <div className='w-11/12 '>
                  <FormControl
                    fullWidth
                    className='bg-[#F9F9F9] mt-4'
                  >
                    <InputLabel
                      id='yemek-kategori'
                      className='font-medium font-[17px]'
                    >
                      Yemek Kategorisi
                    </InputLabel>
                    <Select
                      labelId='yemek-kategori'
                      id='yemek-kategori'
                      label='Yemek Kategorisi'
                      className='font-medium text-[17px]'
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      defaultValue='none'
                    >
                      <MenuItem value={'none'}>Kategori Seçiniz</MenuItem>
                      {categoryList.length > 0 &&
                        categoryList?.map((item, index) => (
                          <MenuItem
                            value={item.name}
                            key={index}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='w-1/12 self-center ml-2'>
                  <Tooltip title='Yemeğinizin kategorisini seçiniz. Yaptığınız seçime göre yemeğiniz listelenecektir. '>
                    <div className='bg-[#F9F9F9] w-fit p-2 rounded-full'>
                      <FaInfo className='text-gray-500' />
                    </div>
                  </Tooltip>
                </div>
              </div>

              <div className='flex'>
                <div className='w-11/12 mt-8 '>
                  <FormControl
                    fullWidth
                    className='bg-[#F9F9F9] mt-4'
                  >
                    <InputLabel
                      id='menu-kategorisi'
                      className='font-medium font-[17px]'
                    >
                      Menü Kategorisi
                    </InputLabel>
                    <Select
                      labelId='menu-kategorisi'
                      id='menu-kategorisi'
                      label='Menü Kategorisi'
                      className='font-medium text-[17px]'
                      value={subCategory}
                      onChange={(e) => setSubCategory(e.target.value)}
                    >
                      <MenuItem value={'none'}>Seçim Yapınız</MenuItem>
                      {subCategoryList?.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className='w-1/12 self-center ml-2 mt-8'>
                  <Tooltip title='Yemeğinizi bir menünün başlığına atıyabilirsiniz. Örnek olarak restoranınızı ziyaret eden müşteriye yemeğinizi "Ayın Avantajlı Menüleri" isimli bir başlık altında göstermek istiyorsanız burdan "Ayın Avantajlı Menüleri" seçeneğini seçmelisiniz.'>
                    <div className='bg-[#F9F9F9] w-fit p-2 rounded-full'>
                      <FaInfo className='text-gray-500' />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className='mt-1'>
                <p
                  onClick={() => setSubCategoryModal(true)}
                  className='ml-1 font-semiBold text-main text-[14px] cursor-pointer'
                >
                  Yeni Ekle
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col lg:flex-row  justify-between gap-x-2'>
          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <CardHeadingText
              title={'Fiyatlandırma'}
              subTitle={'Yemeğinize ait fiyatlandırma ve kampanyaları ekleyiniz.'}
            />

            <div className='mt-4 flex flex-col'>
              <div className='w-11/12'>
                <FormControl
                  fullWidth
                  className='bg-[#F9F9F9] '
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='yemek-fiyati'
                  >
                    Yemek Fiyatı
                  </InputLabel>
                  <OutlinedInput
                    id='yemek-fiyati'
                    label='Yemek Fiyatı'
                    className='font-medium text-[17px]'
                    startAdornment={<InputAdornment position='start'>₺</InputAdornment>}
                    type='number'
                    value={price}
                    onChange={(e) => {
                      const {value} = e.target;
                      const newValue = replaceCommasWithDot(value);
                      setPrice(newValue);
                    }}
                    inputProps={{inputMode: 'numeric'}}
                  />
                </FormControl>
              </div>

              <div className='mt-4 flex items-center'>
                <div className='w-6/12'>
                  <FormControl
                    fullWidth
                    className='bg-[#F9F9F9] '
                  >
                    <InputLabel id='demo-simple-select-label font-medium font-[17px]'>İndirim Tipi</InputLabel>
                    <Select
                      labelId='indirim-tipi'
                      id='indirim-tipi'
                      label='İndirim Tipi'
                      className='font-medium text-[17px]'
                      value={discountType}
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <MenuItem value={'none'}>İndirim Yok</MenuItem>
                      <MenuItem value={'fixed'}>Sabit Fiyat</MenuItem>
                      <MenuItem value={'percent'}>Yüzde</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='flex w-6/12'>
                  <div className='w-11/12 ml-4'>
                    <div>
                      <FormControl
                        fullWidth
                        sx={{m: 1}}
                        className='bg-[#F9F9F9] w-12/12'
                      >
                        <InputLabel
                          className='font-semiBold'
                          htmlFor='indirim-tutarı'
                        >
                          İndirim Tutarı
                        </InputLabel>
                        <OutlinedInput
                          id='indirim-tutarı'
                          label='İndirim Tutarı'
                          className='font-medium text-[17px]'
                          startAdornment={
                            <InputAdornment position='start'>
                              {discountType == 'fixed' ? '₺' : discountType == 'percent' ? '%' : ' '}
                            </InputAdornment>
                          }
                          type='string'
                          disabled={discountType == 'none'}
                          value={discount}
                          onChange={(e) => {
                            const {value} = e.target;
                            const newValue = replaceCommasWithDot(value);
                            setdiscount(newValue);
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>

                  <div className='w-1/12 self-center ml-6 '>
                    <Tooltip
                      title={`İndirim tipi olarak seçebileceğiniz 2 seçenek mevcuttur. Biri "Yüzde" diğeri ise "Sabit Fiyat" 'tır. Eğer "Yüzde" seçili olursa Ürününüzün fiyatından İndirim Tutarına yazdığınız tutar yüzde olarak düşecektir. Örn. Ürününüz 200 TL ve indirim tutarına 20 yazdınız (200*20/100) işleminden ürününüz 160 TL ye satışa çıkacaktır. Eğer "Sabit Fiyat" seçili olursa Ürününüzün fiyatından İndirim Tutarına girdiğiniz değer direk olarak düşecektir. Örn. Ürününüz 200 TL ve İndirim tutarına 20 yazdınız (200-20) işleminden ürününüz 180 TL ye satışa çıkacaktır.`}
                    >
                      <div className='bg-[#F9F9F9] w-fit p-2 rounded-full'>
                        <FaInfo className='text-gray-500' />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              {discountType !== 'none' && discount && (
                <div>
                  <p className='font-medium text-black/60'>
                    Ürünün indirimli fiyatı :{' ₺'}
                    {`${
                      typeof price == 'string' && discount && typeof discount == 'string'
                        ? discountType == 'fixed'
                          ? (parseFloat(price) - parseFloat(discount)).toFixed(2)
                          : (parseFloat(price) - (parseFloat(price) * parseFloat(discount)) / 100).toFixed(2)
                        : console.log(' ')
                    }`}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <div>
              <div>
                <CardHeadingText
                  title={'Zorunlu Seçim Alanları'}
                  subTitle={'Müşterilerin sipariş esnasında seçim yapması zorunlu olduğu alanları belirleyin.'}
                />
              </div>
            </div>
            <div className='mt-4'>
              <div className='flex flex-wrap gap-x-2 gap-y-2'>
                {selectable?.required?.map((item, index) => (
                  <div
                    key={index}
                    className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md items-center '
                  >
                    <div className='flex gap-x-1 items-center'>
                      <div className=''>
                        <Tooltip title={item.active ? 'Aktif' : 'Pasif'}>
                          <div className={`w-2 h-2 rounded-full ${item.active ? 'bg-green-600' : 'bg-main'}`} />
                        </Tooltip>
                      </div>
                      <div>
                        <h6 className='font-bold text-[14px]'>
                          {item.name}
                          <span className='text-[11px] font-medium pl-[2px]'>({`${item.value.length} Opsiyon`})</span>
                        </h6>
                      </div>
                    </div>

                    <div className='flex gap-x-2 text-black/70 text-[19px]'>
                      {/*
                      
                    <button
                        onClick={() => {
                          if (!item.templateID) {
                            setStep(2);
                            setTemplatesValues(item.value);
                            setModalType("opsiyonlar");
                          } else {
                            setModalType("sablonlar");
                            setStep(1);
                          }
                          setEditedIndex(index);
                          setTemplateName(item.name);
                          setModalSelection("required");
                          setSelectableModal(true);
                        }}
                      >
                        <FaRegEdit />
                      </button>*/}
                      <button
                        onClick={() => {
                          const tempObject = {...selectable};
                          tempObject.required.splice(index, 1);
                          setSelectable(tempObject);
                        }}
                      >
                        <HiOutlineTrash />
                      </button>
                    </div>
                  </div>
                ))}

                <div
                  onClick={() => {
                    setEditedIndex(-1);
                    setStep(1);
                    setModalSelection('required');
                    setSelectableModal(true);
                  }}
                  className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md border-dashed items-center bg-[#F9F9F9] cursor-pointer '
                >
                  <div>
                    <h6 className='font-bold text-[14px]'>+ Yeni Alan Ekle</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col lg:flex-row  justify-between gap-x-2'>
          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <CardHeadingText
              title={'Opsiyonel Seçim Alanları'}
              subTitle={'Müşterilerin sipariş esnasında seçim yapması opsiyonel olduğu alanları belirleyin.'}
            />

            <div className='mt-4'>
              <div className='flex'>
                <div className='mt-4'>
                  <div className='flex flex-wrap gap-x-2 gap-y-2'>
                    {selectable?.optional?.map((item, index) => (
                      <div
                        key={index}
                        className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md items-center '
                      >
                        <div className='flex gap-x-1 items-center'>
                          <div className=''>
                            <Tooltip title={item.active ? 'Aktif' : 'Pasif'}>
                              <div className={`w-2 h-2 rounded-full ${item.active ? 'bg-green-600' : 'bg-main'}`} />
                            </Tooltip>
                          </div>
                          <div>
                            <h6 className='font-bold text-[14px]'>
                              {item.name}
                              <span className='text-[11px] font-medium pl-[2px]'>
                                ({`${item.value.length} Opsiyon`})
                              </span>
                            </h6>
                          </div>
                        </div>

                        <div className='flex gap-x-2 text-black/70 text-[19px]'>
                          {/*
                          <button
                            onClick={() => {
                              if (!item.templateID) {
                                setStep(2);
                                setTemplatesValues(item.value);
                                setModalType("opsiyonlar");
                              } else {
                                setModalType("sablonlar");
                                setStep(1);
                              }
                              setEditedIndex(index);
                              setTemplateName(item.name);
                              setModalSelection("optional");
                              setSelectableModal(true);
                            }}
                          >
                            <FaRegEdit />
                          </button>
                         */}
                          <button
                            onClick={() => {
                              const tempObject = {...selectable};
                              tempObject.optional?.splice(index, 1);
                              setSelectable(tempObject);
                            }}
                          >
                            <HiOutlineTrash />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div
                      onClick={() => {
                        setEditedIndex(-1);
                        setStep(1);
                        setModalSelection('optional');
                        setSelectableModal(true);
                      }}
                      className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md border-dashed items-center bg-[#F9F9F9] cursor-pointer '
                    >
                      <div>
                        <h6 className='font-bold text-[14px]'>+ Yeni Alan Ekle</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <CardHeadingText
              title={'Promosyon Seçim Alanları'}
              subTitle={'Müşterilerin sipariş esnasında seçim yapması gereken promosyonları belirleyin.'}
            />

            <div className='mt-4'>
              <div className='flex'>
                <div className='mt-4'>
                  <div className='flex flex-wrap gap-x-2 gap-y-2'>
                    {selectable?.promotion?.map((item, index) => (
                      <div
                        key={index}
                        className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md items-center '
                      >
                        <div className='flex gap-x-1 items-center'>
                          <div className=''>
                            <Tooltip title={item.active ? 'Aktif' : 'Pasif'}>
                              <div className={`w-2 h-2 rounded-full ${item.active ? 'bg-green-600' : 'bg-main'}`} />
                            </Tooltip>
                          </div>
                          <div>
                            <h6 className='font-bold text-[14px]'>
                              {item.name}
                              <span className='text-[11px] font-medium pl-[2px]'>
                                ({`${item.value.length} Opsiyon`})
                              </span>
                            </h6>
                          </div>
                        </div>

                        <div className='flex gap-x-2 text-black/70 text-[19px]'>
                          {/*
                          <button
                            onClick={() => {
                              if (!item.templateID) {
                                setStep(2);
                                setTemplatesValues(item.value);
                                setModalType("opsiyonlar");
                              } else {
                                setModalType("sablonlar");
                                setStep(1);
                              }
                              setEditedIndex(index);
                              setTemplateName(item.name);
                              setModalSelection("promotion");
                              setSelectableModal(true);
                            }}
                          >
                            <FaRegEdit />
                          </button>
                          */}
                          <button
                            onClick={() => {
                              const tempObject = {...selectable};
                              tempObject.promotion?.splice(index, 1);
                              setSelectable(tempObject);
                            }}
                          >
                            <HiOutlineTrash />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div
                      onClick={() => {
                        setEditedIndex(-1);
                        setStep(1);
                        setModalSelection('promotion');
                        setSelectableModal(true);
                      }}
                      className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md border-dashed items-center bg-[#F9F9F9] cursor-pointer '
                    >
                      <div>
                        <h6 className='font-bold text-[14px]'>+ Yeni Alan Ekle</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col lg:flex-row  justify-between gap-x-2'>
          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <CardHeadingText
              title={'Çıkartılabilecek Malzemeler'}
              subTitle={'Müşterilerin sipariş esnasında yemeklerinden çıkarabilecekleri malzemeleri belirle.'}
            />

            <div className='mt-4'>
              <div className='flex'>
                <div className='mt-4'>
                  <div className='flex flex-wrap gap-x-2 gap-y-2'>
                    {removeable?.map((item, index) => (
                      <div
                        key={index}
                        className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md items-center cursor-pointer'
                      >
                        <div>
                          <h6 className='font-bold text-[14px]'>{item.name}</h6>
                        </div>
                        <div className='flex gap-x-2 text-black/70 text-[19px]'>
                          <button
                            onClick={() => {
                              const tempArray = [...removeable];
                              tempArray.splice(index, 1);
                              setRemoveable(tempArray);
                            }}
                          >
                            <HiOutlineTrash />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div
                      onClick={() => {
                        setRemoveableModal(true);
                      }}
                      className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md border-dashed items-center bg-[#F9F9F9] cursor-pointer '
                    >
                      <div>
                        <h6 className='font-bold text-[14px]'>+ Yeni Alan Ekle</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-4 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <CardHeadingText
              title={'Çoklu seçim alanları'}
              subTitle={'Müşterilerin sipariş esnasında birden fazla seçim yapmasını istediğiniz alanları belirleyin.'}
            />

            <div className='mt-4'>
              <div className='flex'>
                <div className='mt-4'>
                  <div className='flex flex-wrap gap-x-2 gap-y-2'>
                    {multiSelect?.map((item, index) => (
                      <div
                        key={index}
                        className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md items-center '
                      >
                        <div className='flex gap-x-1 items-center'>
                          <div className=''>
                            <Tooltip title={item.active ? 'Aktif' : 'Pasif'}>
                              <div className={`w-2 h-2 rounded-full ${item.active ? 'bg-green-600' : 'bg-main'}`} />
                            </Tooltip>
                          </div>
                          <div>
                            <h6 className='font-bold text-[14px]'>
                              {item.name}
                              <span className='text-[11px] font-medium pl-[2px]'>
                                ({`${item.value.length} Opsiyon`})
                              </span>
                            </h6>
                          </div>
                        </div>

                        <div className='flex gap-x-2 text-black/70 text-[19px]'>
                          {/*
                          <button
                            onClick={() => {
                              if (!item.templateID) {
                                setStep(2);
                                setTemplatesValues(item.value);
                                setModalType("opsiyonlar");
                              } else {
                                setModalType("sablonlar");
                                setStep(1);
                              }
                              setEditedIndex(index);
                              setTemplateName(item.name);
                              setModalSelection("multiselect");
                              setSelectableModal(true);
                            }}
                          >
                            <FaRegEdit />
                          </button>
                          */}
                          <button
                            onClick={() => {
                              const tempArray = [...multiSelect];
                              tempArray.splice(index, 1);
                              setMultiSelect(tempArray);
                            }}
                          >
                            <HiOutlineTrash />
                          </button>
                        </div>
                      </div>
                    ))}

                    <div
                      onClick={() => {
                        setModalSelection('multiselect');
                        setSelectableModal(true);
                      }}
                      className='flex p-2 w-3/12 justify-between min-w-[240px] border-[1px] rounded-md border-dashed items-center bg-[#F9F9F9] cursor-pointer '
                    >
                      <div>
                        <h6 className='font-bold text-[14px]'>+ Yeni Alan Ekle</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='my-4 flex flex-col items-center'>
          <button
            className=' bg-main text-white p-2 rounded-md py-3 w-[200px] text-[18px] font-semiBold'
            onClick={createFood}
          >
            Yemeği Oluştur
          </button>
        </div>
      </div>

      <Modal
        open={subCategoryModal}
        onClose={() => setSubCategoryModal(false)}
        className='focus:bg-white'
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            px: 3,
            py: 2,
          }}
        >
          <div>
            <div className='flex justify-between'>
              <h2 className=' text-[19px] font-bold'>Menü Kategorisi Ekle</h2>
              <FaX
                onClick={() => setSubCategoryModal(false)}
                className='cursor-pointer self-center'
              />
            </div>

            <div className='mt-4 pr-2 flex w-full justify-between'>
              <div className='w-full'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] '
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='menu-kategorisi'
                  >
                    Kategori Başlığı
                  </InputLabel>
                  <OutlinedInput
                    id='menu-kategorisi'
                    label='Kategori Başlığı'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='Ana Yemekler'
                    value={subCategoryInput}
                    onChange={(e) => setSubCategoryInput(e.target.value)}
                  />
                </FormControl>
              </div>
            </div>

            <div className='mt-4'>
              <button
                onClick={addSubCategory}
                className={`bg-main py-2 px-4 rounded-md text-white font-bold text-[14px] w-full`}
              >
                Kaydet
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={selectableModal}
        onClose={() => {
          setEditedIndex(-1);
          setStep(1);
          setSelectableModal(false);
        }}
        className='focus:bg-white'
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            maxHeight: '90%',
            overflowY: 'auto',
            bgcolor: 'background.paper',
            px: 2,
            py: 2,
          }}
        >
          <div>
            <div className='flex justify-between'>
              <CardHeadingText
                title={'Şablonlarım'}
                subTitle={'Sisteme kayıtlı şablonlarınızı otomatik olarak içeri aktarabilirsiniz.'}
              />
              <FaX
                onClick={() => {
                  setEditedIndex(-1);
                  setStep(1);
                  setSelectableModal(false);
                }}
                className='cursor-pointer self-center'
              />
            </div>

            <div className='mt-4'>
              <div className='flex gap-x-2'>
                <button
                  onClick={() => setModalType('sablonlar')}
                  className={`${
                    modalType == 'sablonlar' ? 'bg-main text-white' : 'bg-[#F9F9F9] text-black'
                  }  p-2 rounded-lg font-semiBold text-[16px] px-4`}
                >
                  Şablonlarım
                </button>
                <button
                  onClick={() => setModalType('opsiyonlar')}
                  className={`${
                    modalType == 'opsiyonlar' ? 'bg-main text-white' : 'bg-[#F9F9F9] text-black'
                  }  p-2 rounded-lg font-semiBold text-[16px] px-4`}
                >
                  Opsiyon Listem
                </button>
              </div>
            </div>

            {modalType == 'sablonlar' ? (
              <div className='my-4'>
                <div>
                  <FormControl
                    fullWidth
                    sx={{m: 1}}
                    className='bg-[#F9F9F9] '
                  >
                    <InputLabel
                      className='font-semiBold'
                      htmlFor='secim-alani-adi'
                    >
                      Başlık
                    </InputLabel>
                    <OutlinedInput
                      id='secim-alani-adi'
                      label='Başlık'
                      className='font-medium text-[17px]'
                      type='string'
                      placeholder='İçecek Seçimi'
                      value={templateName}
                      onChange={(e) => setTemplateName(e.target.value)}
                    />
                  </FormControl>
                </div>
                {modalSelection == 'multiselect' && (
                  <div className='flex gap-x-2 ml-3 mb-3'>
                    <div className='flex items-center'>
                      <Checkbox
                        checked={selectType == 'required'}
                        sx={{'&.Mui-checked': {color: '#FF0100'}}}
                        onChange={(e) => {
                          setSelectType('required');
                        }}
                      />
                      <p className=' font-medium'>Zorunlu Seçim</p>
                    </div>

                    <div className='flex items-center'>
                      <Checkbox
                        checked={selectType == 'optional'}
                        sx={{'&.Mui-checked': {color: '#FF0100'}}}
                        onChange={(e) => {
                          setSelectType('optional');
                        }}
                      />
                      <p className=' font-medium'>Opsiyonel Seçim</p>
                    </div>
                  </div>
                )}
                <div className='flex gap-x-3'>
                  {templatesData?.map(({_id, active, title, value, rId}, index) => (
                    <div
                      key={index}
                      className='bg-[#F9F9F9] p-4 rounded-lg w-6/12 2xl:w-3/12'
                    >
                      <div className='flex items-center justify-between border-b-[1px] pb-2'>
                        <div>
                          <h2 className='font-semiBold text-[15px]'>{title}</h2>
                        </div>
                        <div>
                          <Tooltip title={active ? 'Aktif' : 'Pasif'}>
                            <div className={`w-3 h-3 ${active ? 'bg-green-600' : 'bg-main'} rounded-full`} />
                          </Tooltip>
                        </div>
                      </div>
                      <div className='mt-4'>
                        {value.map((child, idx) => (
                          <div
                            key={idx}
                            className='flex justify-between'
                          >
                            <div>
                              <p className='font-medium text-gray-600 text-[14px]'>
                                {`${child.name}`}{' '}
                                <span className='text-[12px] text-gray-500'>{`(${
                                  typeof child.price == 'string'
                                    ? parseFloat(child.price) > 0
                                      ? '+'
                                      : ''
                                    : child.price > 0
                                    ? '+'
                                    : ''
                                }${child.price} TL)`}</span>
                              </p>
                            </div>
                            <div>
                              <Tooltip title={child.active ? 'Aktif' : 'Pasif'}>
                                <div className={`w-3 h-3 ${child.active ? 'bg-green-600' : 'bg-main'} rounded-full`} />
                              </Tooltip>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className='mt-8 flex justify-center'>
                        <button
                          onClick={() => {
                            if (templateName.length == 0) {
                              toast.error('Lütfen bir başlık belirleyiniz.');
                            } else {
                              if (modalSelection == 'required') {
                                if (editedIndex === -1) {
                                  let tempArray = {...selectable};
                                  tempArray.required?.push({
                                    name: templateName,
                                    value,
                                    active,
                                    templateID: _id.toString(),
                                    selected: 0,
                                    rId,
                                  });
                                  setSelectable(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                } else {
                                  let tempArray = {...selectable};
                                  tempArray.required[editedIndex] = {
                                    name: templateName,
                                    value,
                                    active,
                                    templateID: _id.toString(),
                                    selected: 0,
                                    rId,
                                  };
                                  setSelectable(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                }
                              } else if (modalSelection == 'optional') {
                                if (editedIndex === -1) {
                                  let tempArray = {...selectable};
                                  tempArray.optional?.push({
                                    name: templateName,
                                    value,
                                    active,
                                    templateID: _id.toString(),
                                    selected: 0,
                                    rId,
                                  });
                                  setSelectable(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                } else {
                                  let tempArray = {...selectable};
                                  if (tempArray.optional) {
                                    tempArray.optional[editedIndex] = {
                                      name: templateName,
                                      value,
                                      active,
                                      templateID: _id.toString(),
                                      selected: 0,
                                      rId,
                                    };
                                  }
                                  setSelectable(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                }
                              } else if (modalSelection == 'promotion') {
                                if (editedIndex === -1) {
                                  let tempArray = {...selectable};
                                  tempArray.promotion?.push({
                                    name: templateName,
                                    value,
                                    active,
                                    templateID: _id.toString(),
                                    selected: 0,
                                    rId,
                                  });
                                  setSelectable(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                } else {
                                  let tempArray = {...selectable};
                                  if (tempArray.promotion) {
                                    tempArray.promotion[editedIndex] = {
                                      name: templateName,
                                      value,
                                      active,
                                      templateID: _id.toString(),
                                      selected: 0,
                                      rId,
                                    };
                                  }
                                  setSelectable(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                }
                              } else {
                                if (editedIndex === -1) {
                                  let tempArray = [...multiSelect];
                                  tempArray?.push({
                                    name: templateName,
                                    value,
                                    active,
                                    templateID: _id.toString(),
                                    selectType,
                                    rId: MainStore?.user?._id,
                                  });
                                  setMultiSelect(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                } else {
                                  let tempArray = [...multiSelect];
                                  tempArray[editedIndex] = {
                                    name: templateName,
                                    value,
                                    active,
                                    templateID: _id.toString(),
                                    selectType,
                                    rId: MainStore?.user?._id,
                                  };
                                  setMultiSelect(tempArray);
                                  setEditedIndex(-1);
                                  setStep(1);
                                  setSelectableModal(false);
                                  setTemplateName('');
                                }
                              }
                            }
                          }}
                          className={`${
                            active ? 'bg-green-600 cursor-pointer' : 'bg-main cursor-not-allowed'
                          } p-2 rounded-md text-white font-bold text-[14px]`}
                        >
                          Şablonu Seç
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                {step == 1 ? (
                  <div>
                    <div className='mt-4'>
                      <FormControl
                        fullWidth
                        sx={{m: 1, pr: 2}}
                        className='bg-[#F9F9F9]'
                      >
                        <InputLabel
                          className='font-semiBold'
                          htmlFor='outlined-adornment-sablon-adi  w-10/12'
                        >
                          Başlık
                        </InputLabel>
                        <OutlinedInput
                          id='secim-alani-adi'
                          label='Başlık'
                          className='font-medium text-[17px]'
                          type='string'
                          placeholder='İçecek Seçimi'
                          value={templateName}
                          onChange={(e) => setTemplateName(e.target.value)}
                        />
                      </FormControl>
                    </div>

                    <div className='mt-4 flex flex-col gap-y-2'>
                      <FormGroup>
                        {optionalData?.map((item, index) => (
                          <div
                            className='flex items-center'
                            key={index}
                          >
                            <Checkbox
                              checked={checkChecked(item)}
                              sx={{'&.Mui-checked': {color: '#FF0100'}}}
                              onChange={(e) => {
                                const check = checkChecked(item);
                                if (check) {
                                  const tempArray = [...templatesValue];
                                  tempArray.map((element, index) => {
                                    if (element.optionalID == item._id) {
                                      tempArray.splice(index, 1);
                                    }
                                  });

                                  setTemplatesValues(tempArray);
                                } else {
                                  const tempArray = [...templatesValue];
                                  tempArray.push({
                                    name: item.name,
                                    price: 0,
                                    optionalID: item._id,
                                    active: item.active,
                                  });
                                  setTemplatesValues(tempArray);
                                }
                              }}
                            />
                            <p className=' font-medium'>{item.name}</p>
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className='mt-4 flex flex-col gap-y-3'>
                      <FormGroup>
                        {templatesValue?.map((item, index) => (
                          <div
                            className='flex items-center'
                            key={index}
                          >
                            <p className=' font-medium'>-{item.name}</p>
                            <FormControl
                              size='small'
                              sx={{m: 1, ml: 3, width: 150}}
                              className='bg-[#F9F9F9]'
                            >
                              <InputLabel
                                className='font-semiBold'
                                htmlFor='outlined-adornment-opsiyon-adi  w-4/12'
                              >
                                Opsiyon Fiyatı
                              </InputLabel>
                              <OutlinedInput
                                startAdornment={<InputAdornment position='start'>₺</InputAdornment>}
                                id='outlined-adornment-opsiyon-fiyati'
                                label='Opsiyon Fiyatı'
                                className='font-medium text-[17px]'
                                type='number'
                                placeholder='Büyük boy pizza'
                                value={item.price}
                                onChange={(e) => {
                                  const tempArray = [...templatesValue];
                                  tempArray.map((element, index) => {
                                    if (item.optionalID == element.optionalID) {
                                      if (e.target.value == '') {
                                        element.price = '';
                                      } else {
                                        const newPrice = parseFloat(e.target.value);
                                        if (!isNaN(newPrice)) {
                                          element.price = e.target.value;
                                        }
                                      }
                                    }
                                  });
                                  setTemplatesValues(tempArray);
                                }}
                              />
                            </FormControl>
                          </div>
                        ))}
                      </FormGroup>
                    </div>
                  </div>
                )}
              </div>
            )}

            {modalType == 'opsiyonlar' && (
              <div className='mt-4'>
                <button
                  onClick={() => {
                    if (step == 1) {
                      if (templateName.length > 0 && templatesValue.length > 0) {
                        setStep(2);
                      } else if (templatesValue.length == 0) {
                        toast.error('Lütfen en az 1 tane opsiyon seçiniz');
                      } else {
                        toast.error('Lütfen alana bir başlık verin.');
                      }
                    } else {
                      let error = false;
                      templatesValue.map((item) => {
                        if (!item.price) {
                          error = true;
                        }
                      });

                      if (error) {
                        toast.error('Lütfen tüm fiyatları doldurunuz.');
                      } else {
                        setStep(1);
                        setTemplatesValues([]);
                        const value = [...templatesValue];
                        value.map((item) => {
                          item.optionalID = item._id;
                        });
                        if (modalSelection == 'required') {
                          let tempObject = {...selectable};
                          if (editedIndex === -1) {
                            tempObject.required?.push({
                              name: templateName,
                              value,
                              active: true,
                              selected: 0,
                              rId: MainStore.user._id,
                            });
                          } else {
                            tempObject.required[editedIndex] = {
                              name: templateName,
                              value,
                              active: true,
                              selected: 0,
                              rId: MainStore.user._id,
                            };
                          }
                          setSelectable(tempObject);
                          setEditedIndex(-1);
                          setStep(1);
                          setSelectableModal(false);
                          setTemplateName('');
                        } else if (modalSelection == 'optional') {
                          let tempObject = {...selectable};
                          if (editedIndex === -1) {
                            tempObject.optional?.push({
                              name: templateName,
                              value,
                              active: true,
                              selected: 0,
                              rId: MainStore.user._id,
                            });
                          } else {
                            if (tempObject.optional) {
                              tempObject.optional[editedIndex] = {
                                name: templateName,
                                value,
                                active: true,
                                selected: 0,
                                rId: MainStore.user._id,
                              };
                            }
                          }
                          setSelectable(tempObject);
                          setEditedIndex(-1);
                          setStep(1);
                          setSelectableModal(false);
                          setTemplateName('');
                        } else if (modalSelection == 'promotion') {
                          let tempObject = {...selectable};
                          if (editedIndex === -1) {
                            tempObject.promotion?.push({
                              name: templateName,
                              value,
                              active: true,
                              selected: 0,
                              rId: MainStore.user._id,
                            });
                          } else {
                            if (tempObject.promotion) {
                              tempObject.promotion[editedIndex] = {
                                name: templateName,
                                value,
                                active: true,
                                selected: 0,
                                rId: MainStore.user._id,
                              };
                            }
                          }
                          setSelectable(tempObject);
                          setEditedIndex(-1);
                          setStep(1);
                          setSelectableModal(false);
                          setTemplateName('');
                        } else {
                          let tempArray = [...multiSelect];
                          if (editedIndex === -1) {
                            tempArray?.push({
                              name: templateName,
                              value,
                              active: true,
                              selectType,
                              rId: MainStore?.user?._id,
                            });
                          } else {
                            tempArray[editedIndex] = {
                              name: templateName,
                              value,
                              active: true,
                              selectType,
                              rId: MainStore?.user?._id,
                            };
                          }
                          setMultiSelect(tempArray);
                          setEditedIndex(-1);
                          setStep(1);
                          setSelectableModal(false);
                          setTemplateName('');
                        }
                      }
                    }
                  }}
                  className={`bg-main py-2 px-4 rounded-md text-white font-bold text-[14px] w-full`}
                >
                  {step == 1 ? 'Fiyat Belirle' : 'Kaydet'}
                </button>
                {step == 2 ||
                  (editedIndex === -1 && (
                    <button
                      className='font-semiBold text-[15px] text-center text-main self-center items-center justify-center'
                      onClick={() => setStep(1)}
                    >
                      Geri Dön
                    </button>
                  ))}
              </div>
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={removeableModal}
        onClose={() => setRemoveableModal(false)}
        className='focus:bg-white'
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            px: 3,
            py: 2,
          }}
        >
          <div>
            <div className='flex justify-between'>
              <h2 className=' text-[19px] font-bold'>Çıkartılabilecek Malzemeler</h2>
              <FaX
                onClick={() => setSubCategoryModal(false)}
                className='cursor-pointer self-center'
              />
            </div>

            <div className='mt-4 pr-2 flex w-full justify-between'>
              <div className='w-full'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] '
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='cikartilacak-malzemeler'
                  >
                    Malzeme
                  </InputLabel>
                  <OutlinedInput
                    id='cikartilacak-malzemeler'
                    label='Malzeme'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='Mayonez'
                    value={removeableInput}
                    onChange={(e) => setRemoveableInput(e.target.value)}
                  />
                </FormControl>
              </div>
            </div>

            <div className='mt-4'>
              <button
                onClick={() => {
                  const tempArray = [...removeable];
                  tempArray.push({name: removeableInput});
                  setRemoveable(tempArray);
                  setRemoveableInput('');
                  setRemoveableModal(false);
                }}
                className={`bg-main py-2 px-4 rounded-md text-white font-bold text-[14px] w-full`}
              >
                Kaydet
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </AuthLayout>
  );
}

export default Page;
