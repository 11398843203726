import * as React from "react";
import type { SVGProps } from "react";
const AllOrder = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
        {...props}
    >
        <path
            stroke="#28303F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m15 6.667 3.42 2.735a1 1 0 0 0 1.377-.122L25 3.333"
        />
        <path
            stroke="#28303F"
            strokeWidth={1.5}
            d="M6.667 16.667v12.097a2 2 0 0 0 1.105 1.789l11.334 5.666a2 2 0 0 0 1.788 0l11.334-5.666a2 2 0 0 0 1.105-1.789V16.667"
        />
        <path
            stroke="#28303F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M30 8.333 33.333 10 20 16.667 6.667 10 10 8.333"
        />
        <path
            stroke="#28303F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M33.333 10 20 16.667l3.333 5L36.667 15zM6.667 10 20 16.667l-3.333 5L3.333 15z"
        />
    </svg>
);
export default AllOrder;