import * as React from "react";
import type { SVGProps } from "react";
const DownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={15}
        fill="none"
        {...props}
    >
        <path
            stroke="#F84E4E"
            strokeWidth={2}
            d="M19.5 13.5c-.918-1.084-3.897-4.272-5.5-6l-6 3-7-9"
        />
        <path fill="url(#a)" d="M14 7.5c1.603 1.728 4.582 4.916 5.5 6H1v-12l7 9z" />
        <defs>
            <linearGradient
                id="a"
                x1={10.25}
                x2={9.5}
                y1={3}
                y2={13.5}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F84E4E" stopOpacity={0.73} />
                <stop offset={1} stopColor="#F84E4E" stopOpacity={0} />
            </linearGradient>
        </defs>
    </svg>
);
export default DownIcon;