import CardHeadingText from '../../components/Text/CardHeadingText';
import HeadingText from '../../components/Text/HeadingText';
import MainStore from '../../store/MainStore';
import {Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, OutlinedInput} from '@mui/material';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import AuthLayout from '../../layout/AuthLayout';

type DeliveryOptionsProps = {
  takeAway: boolean;
  onlinePayment: boolean;
  cashPayment: boolean;
  creditCard: boolean;
};

type WorkingHoursProps = {
  sunday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
};

function Ayarlar() {
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptionsProps | null>(MainStore.user.deliveryOptions);
  const [workingHours, setWorkingHours] = useState<WorkingHoursProps | null>(MainStore.user.workingHours);
  const [autoAccept, setAutoAccept] = useState<boolean>(
    MainStore?.user?.autoAccept ? MainStore.user.autoAccept : false
  );
  const [twoFA, setTwoFA] = useState<boolean>(MainStore.user.twoFA ? MainStore.user.twoFA : false);

  function handleChangeWorkingHours(day: keyof WorkingHoursProps, value: string) {
    setWorkingHours((prevState) => {
      if (prevState !== null) {
        return {
          ...prevState,
          [day]: value,
        };
      } else {
        return null;
      }
    });
  }

  function handleChangeDeliveryOption(option: keyof DeliveryOptionsProps, checked: boolean) {
    setDeliveryOptions((prevState) => {
      if (prevState !== null) {
        return {
          ...prevState,
          [option]: checked,
        };
      } else {
        return null;
      }
    });
  }

  const saveDeliveryOptions = () => {
    console.log(deliveryOptions);
    axios
      .post(
        'https://api.yemek.club/restaurant/api/editMyRestaurant',
        {
          deliveryOptions,
          twoFA,
          autoAccept,
        },
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        if (res.data.success) {
          toast.success('Restoran ayarlarınız başarıyla güncellendi.');
        } else {
          toast.error('Bir sorun oluştu lütfen daha sonra tekrar deneyiniz.');
        }
      });
  };

  const saveWorkingHours = () => {
    axios
      .post(
        'https://api.yemek.club/restaurant/api/editMyRestaurant',
        {
          workingHours,
        },
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        if (res.data.success) {
          toast.success('Çalışma saatleriniz başarıyla güncellenmiştir.');
        } else {
          toast.error('Bir sorun oluştu lütfen daha sonra tekrar deneyiniz.');
        }
      });
  };

  return (
    <AuthLayout>
      <div>
        <div className='mt-8'>
          <HeadingText
            title='Ayarlar'
            subTitle={'Restoranınızın ödeme yöntemlerini ve çalışma saatlerini belirleyebilirsiniz ! '}
          />
        </div>
        <div className='flex flex-col lg:flex-row justify-between'>
          <div className='mt-8 bg-white rounded-md w-full lg:w-6/12 p-4'>
            <div className=' border-b-[1px] pb-4 '>
              <div>
                <CardHeadingText
                  title={'Ödeme Seçenekleri'}
                  subTitle={'Müşteriler sipariş verirken hangi ödeme yöntemlerini kullanabileceğini belirleyiniz.'}
                />
              </div>

              <div className='mt-4 flex-wrap flex gap-y-2'>
                <div className='w-12/12 md:w-6/12'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeDeliveryOption('onlinePayment', e.target.checked)}
                        checked={deliveryOptions?.onlinePayment}
                        sx={{'&.Mui-checked': {color: '#FF0100'}}}
                      />
                    }
                    label='Online Ödeme'
                    className='text-[15px]  font-semiBold'
                  />
                </div>
                <div className='w-12/12 md:w-5/12'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeDeliveryOption('takeAway', e.target.checked)}
                        checked={deliveryOptions?.takeAway}
                        sx={{'&.Mui-checked': {color: '#FF0100'}}}
                      />
                    }
                    label='Gel-Al Servis'
                    className='text-[15px]  font-semiBold'
                  />
                </div>
                <div className='w-12/12 md:w-6/12'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeDeliveryOption('cashPayment', e.target.checked)}
                        checked={deliveryOptions?.cashPayment}
                        sx={{'&.Mui-checked': {color: '#FF0100'}}}
                      />
                    }
                    label='Kapıda Nakit Ödeme'
                    className='text-[15px]  font-semiBold'
                  />
                </div>
                <div className='w-12/12 md:w-5/12'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeDeliveryOption('creditCard', e.target.checked)}
                        checked={deliveryOptions?.creditCard}
                        sx={{'&.Mui-checked': {color: '#FF0100'}}}
                      />
                    }
                    label='Kapıda Kredi Kartı'
                    className='text-[15px]  font-semiBold'
                  />
                </div>
              </div>
            </div>

            <div className='mt-4'>
              <div>
                <CardHeadingText
                  title={'Genel Ayarlar'}
                  subTitle={' '}
                />
              </div>

              <div className='flex flex-col mt-2'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={autoAccept}
                      onChange={(e) =>
                        toast.error('Bu özellik yapım aşamasındadır. Lütfen daha sonra tekrar deneyiniz.')
                      }
                      sx={{'&.Mui-checked': {color: '#FF0100'}}}
                    />
                  }
                  label='Gelen siparişleri otomatik onayla'
                  className='text-[15px]  font-semiBold'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={twoFA}
                      onChange={(e) => setTwoFA(e.target.checked)}
                      sx={{'&.Mui-checked': {color: '#FF0100'}}}
                    />
                  }
                  label='Hesabıma giriş yaparken telefon numarama doğrulama kodu gönder'
                  className='text-[15px]  font-semiBold'
                />
              </div>
            </div>

            <div className='mt-4'>
              <button
                onClick={saveDeliveryOptions}
                className='bg-main p-3 rounded-md text-white font-bold'
              >
                Değişiklikleri Kaydet
              </button>
            </div>
          </div>

          <div className='mt-8 bg-white rounded-md w-full lg:w-6/12 p-4 ml-2'>
            <div>
              <CardHeadingText
                title={'Çalışma Saatleri'}
                subTitle={
                  'Müşterilerinize hangi saatler arasında çalıştığınızı belirtiniz. (Yazdığınız saatlere göre restoranınız otomatik açılıp kapanmaz sadece bilgi amaçlıdır)'
                }
              />
            </div>
            <div className='mt-4 flex-wrap flex justify-between gap-y-2'>
              <div className='w-6/12 lg:w-4/12'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-12/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='outlined-adornment-amount'
                  >
                    Pazartesi
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    label='Pazartesi'
                    className='font-medium text-[17px]'
                    type='string'
                    value={workingHours?.monday}
                    onChange={(e) => handleChangeWorkingHours('monday', e.target.value)}
                  />
                </FormControl>
              </div>
              <div className='w-6/12 lg:w-4/12 pl-2 '>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-12/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='outlined-adornment-amount'
                  >
                    Salı
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    label='Salı'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='08:00-21:00'
                    value={workingHours?.tuesday}
                    onChange={(e) => handleChangeWorkingHours('tuesday', e.target.value)}
                  />
                </FormControl>
              </div>
              <div className='w-6/12 lg:w-4/12 lg:pl-2'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-12/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='outlined-adornment-amount'
                  >
                    Çarşamba
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    label='Çarşamba'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='08:00-21:00'
                    value={workingHours?.wednesday}
                    onChange={(e) => handleChangeWorkingHours('wednesday', e.target.value)}
                  />
                </FormControl>
              </div>
              <div className='w-6/12 lg:w-4/12 pl-2 lg:pl-0'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-12/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='outlined-adornment-amount'
                  >
                    Perşembe
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    label='Perşembe'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='08:00-21:00'
                    value={workingHours?.thursday}
                    onChange={(e) => handleChangeWorkingHours('thursday', e.target.value)}
                  />
                </FormControl>
              </div>
              <div className='w-6/12 lg:w-4/12 lg:pl-2 '>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-12/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='outlined-adornment-amount'
                  >
                    Cuma
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    label='Cuma'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='08:00-21:00'
                    value={workingHours?.friday}
                    onChange={(e) => handleChangeWorkingHours('friday', e.target.value)}
                  />
                </FormControl>
              </div>
              <div className='w-6/12 lg:w-4/12 pl-2'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-12/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='outlined-adornment-amount'
                  >
                    Cumartesi
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    label='Cumartesi'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='08:00-21:00'
                    value={workingHours?.saturday}
                    onChange={(e) => handleChangeWorkingHours('saturday', e.target.value)}
                  />
                </FormControl>
              </div>
              <div className='w-6/12 lg:w-4/12'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-12/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='outlined-adornment-amount'
                  >
                    Pazar
                  </InputLabel>
                  <OutlinedInput
                    id='outlined-adornment-amount'
                    label='Pazar'
                    className='font-medium text-[17px]'
                    type='string'
                    placeholder='08:00-21:00'
                    value={workingHours?.sunday}
                    onChange={(e) => handleChangeWorkingHours('sunday', e.target.value)}
                  />
                </FormControl>
              </div>
            </div>
            <div className='mt-4'>
              <button
                onClick={saveWorkingHours}
                className='bg-main p-3 rounded-md text-white font-bold'
              >
                Değişiklikleri Kaydet
              </button>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Ayarlar;
