import {FormControl, InputAdornment, InputLabel, OutlinedInput} from '@mui/material';
import axios from 'axios';
import React, {useState} from 'react';
import toast, {Toaster} from 'react-hot-toast';
import {FaMobile, FaPhone, FaUser} from 'react-icons/fa';
import {FaAt, FaKey, FaLock} from 'react-icons/fa6';
import MainStore from '../../store/MainStore';
import {observer} from 'mobx-react';
function Login() {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [authCode, setAuthCode] = useState<string>('');
  const [enable2FA, setEnable2FA] = useState<boolean>(false);

  const handleLogin = () => {
    if (phoneNumber.length > 0) {
      if (phoneNumber.length === 11) {
        if (authCode.length == 0 || authCode.length == 6) {
          axios
            .post('https://api.yemek.club/restaurant/api/login', {
              phoneNumber,
              authCode,
            })
            .then((res) => {
              if (res.data.enable2FA) {
                toast.success(
                  'Sisteme kayıtlı olan numaranıza tek kullanımlık doğrulama kodu başarıyla gönderilmiştir.'
                );
                setEnable2FA(true);
              } else if (res.data.user) {
                MainStore.login(true, res.data.token, res.data.user);
                toast.success('Giriş işlemi başarılı. Hesabınıza yönlendiriliyorsunuz...');
              } else if (res.data.error) {
                toast.error(res.data.error.message);
              } else {
                toast.error(
                  'Bir sorun oluştu lütfen daha sonra tekrar deneyiniz. Sorunun çözülmemesi durumunda çözüm merkezimize ulaşınız.'
                );
              }
            });
        } else if (authCode.length != 6) {
          toast.error('Doğrulama kodu 6 haneli olmalıdır.');
        }
      } else {
        toast.error('Lütfen telefon numaranızı başında 0 ve 11 karakter olacak şekilde doldurunuz.');
      }
    } else {
      toast.error('Telefon Numarası alanı boş bırakılamaz.');
    }
  };
  return (
    <div className='bg-[#F9F9F9]'>
      <div className='mx-auto h-screen flex items-center justify-center  '>
        <div className='bg-white w-11/12 md:w-9/12 lg:w-6/12 2xl:w-4/12   rounded-lg p-8'>
          <div className='flex flex-col items-center '>
            <div className='bg-main font-black text-[43px] px-4 rounded-lg text-white'>
              <p onClick={() => console.log(MainStore.auth)}>R</p>
            </div>

            <div className='w-full text-center mt-4'>
              <h2 className=' font-bold text-[32px]'>Hoşgeldiniz</h2>
            </div>
          </div>

          <div className='mt-24 flex flex-col gap-y-4 '>
            <div className={`flex flex-col items-center`}>
              <FormControl
                fullWidth
                sx={{m: 1}}
                className={`bg-[#F9F9F9] w-10/12`}
              >
                <InputLabel
                  className='font-semiBold'
                  htmlFor='number'
                >
                  Telefon Numaranız
                </InputLabel>
                <OutlinedInput
                  id='phonenumber'
                  startAdornment={
                    <InputAdornment position='start'>
                      <FaMobile />
                    </InputAdornment>
                  }
                  label='Telefon Numaranız'
                  className='font-medium text-[17px]'
                  type='tel'
                  placeholder='05412345678'
                  disabled={enable2FA}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </FormControl>
            </div>

            {enable2FA && (
              <div className='flex flex-col items-center'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9] w-10/12'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='2FA'
                  >
                    2 Adımlı Doğrulama
                  </InputLabel>
                  <OutlinedInput
                    id='2FA'
                    startAdornment={
                      <InputAdornment position='start'>
                        <FaKey />
                      </InputAdornment>
                    }
                    label='2 Adımlı Doğrulama'
                    className='font-medium text-[17px]'
                    type='text'
                    placeholder='123456'
                    value={authCode}
                    onChange={(e) => setAuthCode(e.target.value)}
                  />
                </FormControl>
              </div>
            )}
          </div>

          <div className='mt-24 flex flex-col items-center'>
            <button
              onClick={handleLogin}
              className='bg-main text-white p-4 rounded-lg font-bold text-[16px] px-12'
            >
              Giriş Yap
            </button>
            {/*
            <p className=" text-black/50 font-medium mt-2 ">
              Hesabıma giriş yapamıyorum.
            </p>
          */}
          </div>
        </div>
      </div>
      <Toaster position='bottom-right' />
    </div>
  );
}

export default observer(Login);
