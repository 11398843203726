import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import DashboardPage from './screens/dashboard/DashboardPage';
import Ayarlar from './screens/ayarlar/Ayarlar';
import Bolgeler from './screens/bolgeler/page';
import YeniBolge from './screens/bolgeler/yeni-ekle/page';
import Destek from './screens/destek/page';
import DestekDetay from './screens/destek/detay/page';
import GuvenlikAyarlari from './screens/guvenlik-ayarlari/page';
import Yorumlar from './screens/yorumlar/page';
import Hizmetlerimiz from './screens/hizmetlerimiz/page';
import Opsiyonlar from './screens/opsiyonlar/page';
import Siparisler from './screens/siparisler/page';
import SiparisDetay from './screens/siparisler/detay/page';
import Yemekler from './screens/yemekler/page';
import YemekEkle from './screens/yemekler/yemek-ekle/page';
import YemekDuzenle from './screens/yemekler/yemek-duzenle/page';
import TopluFiyatGuncelle from './screens/yemekler/toplu-fiyat-guncelle/page';
import MainStore from './store/MainStore';
import Login from './screens/auth/Login';
import {observer} from 'mobx-react';
import {io} from 'socket.io-client';
import axios from 'axios';
import useSound from 'use-sound';
import AuthPage404 from './screens/404/auth/AuthPage404';
import Page404 from './screens/404/notauth/Page404';
import SetLocalStorage from './screens/auth/SetLocalStorage';

const socket = io(`https://api.yemek.club`);

function App() {
  const [appReady, setAppReady] = useState<boolean>(false);
  const [play] = useSound('https://restaurant.yemek.club/assets/audio/notification.mp3');

  useEffect(() => {
    socket.on('connect', () => {
      socket.emit('subscribe', 'newOrder');
    });

    socket.on('newOrder', (data: {rId: string}) => {
      if (data.rId == MainStore.user._id) {
        console.log('geldi');
        play();
      }
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios
        .get('https://api.yemek.club/restaurant/api/getMyInfo', {
          headers: {Authorization: `Bearer ${token}`},
        })
        .then((res) => {
          if (!res.data.error) {
            MainStore.login(true, token, res.data);
            setAppReady(true);
          } else {
            localStorage.clear();
            setAppReady(true);
          }
        })
        .catch((e) => console.log(e));
    } else {
      setAppReady(true);
    }

    return () => setAppReady(false);
  }, []);

  if (!appReady) {
    return null;
  }
  return (
    appReady && (
      <BrowserRouter basename='/'>
        {MainStore.auth ? (
          <Routes>
            <Route
              path='/'
              element={<DashboardPage />}
            />
            <Route
              path='/ayarlar'
              element={<Ayarlar />}
            />
            <Route
              path='/bolgeler'
              element={<YeniBolge />}
            />
            <Route
              path='/destek'
              element={<Destek />}
            />
            <Route
              path='/destek/detay/:id'
              element={<DestekDetay />}
            />
            <Route
              path='/guvenlik-ayarlari'
              element={<GuvenlikAyarlari />}
            />
            <Route
              path='/hizmetlerimiz'
              element={<Hizmetlerimiz />}
            />
            <Route
              path='/opsiyonlar'
              element={<Opsiyonlar />}
            />
            <Route
              path='/siparisler'
              element={<Siparisler socket={socket} />}
            />
            <Route
              path='/siparisler/detay/:id'
              element={<SiparisDetay />}
            />
            <Route
              path='/yemekler'
              element={<Yemekler />}
            />
            <Route
              path='/yemekler/yemek-duzenle/:id'
              element={<YemekDuzenle />}
            />
            <Route
              path='/yemek-ekle'
              element={<YemekEkle />}
            />
            <Route
              path='/yemekler/toplu-fiyat-guncelle'
              element={<TopluFiyatGuncelle />}
            />
            <Route
              path='/yorumlar'
              element={<Yorumlar />}
            />
            <Route
              path='*'
              element={<AuthPage404 />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route
              path='/'
              element={<Login />}
            />
               <Route
              path='/setLocalStorage'
              element={<SetLocalStorage />}
            />
            <Route
              path='*'
              element={<Navigate to='/' />}
            />
            
          </Routes>
        )}
      </BrowserRouter>
    )
  );
}

export default observer(App);
