import React from "react";
import CardHeadingText from "../Text/CardHeadingText";
import PopularMenuItem from "../Cards/PopularMenuItem";

type DataProps = {
  foodImage: string;
  name: string;
  totalSale: number;
};

function PopularMenuSection({ data }: { data: DataProps[] }) {
  const firstFiveItems = data.slice(0, 5);
  const lastFiveItems = data.slice(5, 10);
  return (
    <div>
      <div>
        <CardHeadingText
          title={"Popüler Menüler"}
          subTitle={"En çok sipariş edilen 10 yemeğiniz"}
        />
      </div>
      <div className="flex justify-between ">
        <div className="flex flex-col gap-y-6 md:gap-y-4 pt-4">
          {firstFiveItems.map((item, index) => (
            <PopularMenuItem
              foodImage={item?.foodImage}
              name={item.name}
              totalSale={item.totalSale}
            />
          ))}
        </div>
        <div className="flex flex-col gap-y-6 md:gap-y-4 pt-4">
          {lastFiveItems.map((item, index) => (
            <PopularMenuItem
              foodImage={item.foodImage}
              name={item.name}
              totalSale={item.totalSale}
            />
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-col" />
      </div>
    </div>
  );
}

export default PopularMenuSection;
