import ShowIcon from '../../components/Icon/Order/ShowIcon';
import HeadingText from '../../components/Text/HeadingText';
import {DataGrid, GridColDef, GridRowsProp} from '@mui/x-data-grid';
import {trTR} from '@mui/x-data-grid/locales';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import Modal from '@mui/material/Modal';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import CardHeadingText from '../../components/Text/CardHeadingText';
import axios from 'axios';
import MainStore from '../../store/MainStore';
import AuthLayout from '../../layout/AuthLayout';
import NotificationRequestModal from '../../components/Modal/NotificationRequestModal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
};

function Page() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [priority, setPriority] = useState<string>('');
  const [department, setDepartment] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [rows, setRows] = useState<GridRowsProp | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const columns: GridColDef[] = [
    {
      field: 'shortID',
      headerName: 'Destek ID',
      minWidth: 150,
      flex: 0.1,
      renderCell: (params) => <div className={` text-[#3E4954] font-semiBold text-[16px]`}>{params.row.shortID}</div>,
    },
    {
      field: 'department',
      headerName: 'Departman',
      minWidth: 160,
      flex: 0.1,
      renderCell: (params) => <div className={`text-[#3E4954] font-semiBold`}>{params.row.department}</div>,
    },
    {
      field: 'title',
      headerName: 'Başlık',
      minWidth: 240,
      flex: 0.3,
      renderCell: (params) => <div className={` text-[#3E4954] font-semiBold`}>{params.row.title}</div>,
    },
    {
      field: 'updatedDate',
      headerName: 'Son Güncelleme',
      minWidth: 110,
      flex: 0.2,
      renderCell: (params) => (
        <div className={` text-[#3E4954] font-semiBold`}>{`${moment(params.row.updatedDate).format(
          'DD.MM.YYYY'
        )} (${moment(params.row.updatedDate).format('HH:MM')})`}</div>
      ),
    },
    {
      field: 'status',
      headerName: 'Son Durum',
      minWidth: 150,
      maxWidth: 150,
      flex: 0.2,
      renderCell: (params) => (
        <div
          className={`w-fit ${
            params.row.status == 'Kapandı'
              ? 'text-main bg-main/5  px-[16px] rounded-md'
              : params.row.status == 'Bekleniyor'
              ? 'text-[#FF6D4D] bg-[#FF6D4D]/5   px-[16px] rounded-md'
              : params.row.status == 'Yanıtlandı'
              ? 'text-[#2BC155] bg-[#2BC155]/5   px-[16px] rounded-md'
              : params.row.status == 'Yolda'
              ? 'text-[#2F4CDD] bg-[#2F4CDD]/5   px-[16px] rounded-md'
              : 'text-[#3E4954]'
          } font-bold`}
        >
          {params.row.status}
        </div>
      ),
    },
    {
      field: 'col6',
      headerName: 'İşlem',
      width: 10,
      flex: 0.05,
      align: 'center',
      renderCell: (params) => (
        <a
          href={`/destek/detay/${params.row._id}`}
          className='flex gap-x-2 self-center mt-3'
        >
          <ShowIcon className='cursor-pointer' />
        </a>
      ),
      renderHeader: (params) => <></>,
      filterable: false,
      sortable: false,
      hideSortIcons: false,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    axios
      .get('https://api.yemek.club/restaurant/api/getMyTickets', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setRows(res.data);
        setIsLoading(false);
      });
  }, [isLoading]);

  const createTicket = () => {
    setIsLoading(true);
    axios
      .post(
        'https://api.yemek.club/restaurant/api/createTicket',
        {
          title,
          priority,
          department,
          message,
        },
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        console.log(res.data);
      });
    setIsLoading(false);
    setModalOpen(!modalOpen);
  };

  return (
    <AuthLayout>
      <div>
        <div className='mt-8 flex justify-between'>
          <div>
            <HeadingText
              title={'Destek'}
              subTitle={'7/24 Bizi arayıp destek talebi oluşturabilirsiniz!'}
            />
          </div>
          <div>
            <button
              onClick={() => setModalOpen(true)}
              className='bg-main p-2 rounded-md text-white font-bold'
            >
              Destek Talebi Oluştur
            </button>
          </div>
        </div>

        <div className='mt-8 w-full  bg-white p-8 rounded-[20px]'>
          <DataGrid
            rows={rows}
            loading={isLoading}
            getRowId={(row) => row._id}
            columns={columns}
            sx={{
              border: 0,
              borderColor: '#D3D6E4',
              '.MuiDataGrid-columnHeader': {
                fontFamily: 'Gilroy-SemiBold',
                fontSize: 18,
              },
              '.MuiDataGrid-cell': {border: 0},
              '.MuiDataGrid-footerCell': {paddingBottom: 10},
              '.MuiDataGrid-footerContainer': {border: 0},
            }}
            localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
          />
        </div>

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby='modal-modal-destek'
          aria-describedby='modal-modal-destek-description'
        >
          <Box sx={style}>
            <div className='flex flex-col gap-y-1 p-4'>
              <div>
                <CardHeadingText
                  title='Destek Talebi Oluştur'
                  subTitle={' '}
                />
              </div>
              <div className='mt-4 pr-4'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9]'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='ticket-baslik'
                  >
                    Başlık
                  </InputLabel>
                  <OutlinedInput
                    id='ticket-baslik'
                    label='Başlık'
                    className='font-medium text-[17px]'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormControl>
              </div>

              <div className='mt-4 pr-4 ml-2 flex justify-between'>
                <div className='w-[48%]'>
                  <TextField
                    id='ticket-oncelik'
                    select
                    label='Öncelik'
                    className='font-medium text-[17px] bg-[#F9F9F9] w-full '
                    defaultValue='Düşük'
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    <MenuItem value={'düşük'}>Düşük</MenuItem>
                    <MenuItem value={'orta'}>Orta</MenuItem>
                    <MenuItem value={'yüksek'}>Yüksek</MenuItem>
                  </TextField>
                </div>

                <div className='w-[48%]'>
                  <TextField
                    id='ticket-departman'
                    select
                    label='Departman'
                    className='font-medium text-[17px] bg-[#F9F9F9] w-full '
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    placeholder='Seçim Yapınız'
                  >
                    <MenuItem value={'Satış Departmanı'}>Satış Departmanı</MenuItem>
                    <MenuItem value={'Teknik Destek'}>Teknik Destek</MenuItem>
                  </TextField>
                </div>
              </div>

              <div className='mt-4 pr-4'>
                <FormControl
                  fullWidth
                  sx={{m: 1}}
                  className='bg-[#F9F9F9]'
                >
                  <InputLabel
                    className='font-semiBold'
                    htmlFor='ticket-baslik'
                  >
                    Mesajınız
                  </InputLabel>
                  <OutlinedInput
                    id='ticket-mesaj'
                    label='Mesajınız'
                    multiline
                    className='font-medium text-[17px]'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormControl>
              </div>

              <div>
                <button
                  onClick={createTicket}
                  className='bg-main m-2 p-2 rounded-md text-white font-bold'
                >
                  Destek Talebi Oluştur
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </AuthLayout>
  );
}

export default Page;
