import { LinearProgress } from '@mui/material'
import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface StatsProps {
    title: String;
    value: number;
    stats: String;
    color: 'primary' | 'secondary' | 'success' | 'error';
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#3E4954'
        },
        secondary: {
            main: '#4477CE'
        },
        success: {
            main: '#2BC155'
        },
        error: {
            main: '#FF0100'
        }

    },
});

function OrderStatsProgress({ title, value, stats, color }: StatsProps) {
    return (
        <div className='flex flex-1 gap-x-6  justify-start items-center'>
            <div className='text-[#3E4954] font-semibold text-[14px] w-5/12 md:w-3/12 xl:w-4/12'>
                <p>{title}</p>
            </div>
            <div className='w-6/12'>
                <ThemeProvider theme={theme}>
                    <LinearProgress value={value} variant='determinate' sx={{ height: 10, borderRadius: 8 }} color={color} />
                </ThemeProvider>
            </div>
            <div className='text-[#3E4954] font-semibold text-[14px] w-2/12'>
                <p>{stats}</p>
            </div>
        </div>
    )
}

export default OrderStatsProgress