import HeadingText from "../../components/Text/HeadingText";
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Typography } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import MainStore from "../../store/MainStore";
import AuthLayout from "../../layout/AuthLayout";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

function Page() {
  const [qrOpen, setQrOpen] = useState<boolean>(false);
  return (
    <AuthLayout>
      <div>
        <div className="mt-8">
          <HeadingText
            title={"Hizmetlerimiz"}
            subTitle={
              "Yemek.Club'ın üye işyerleri için olan eşsiz hizmetlerini hemen keşfedin!"
            }
          />
        </div>

        <div className="flex flex-col gap-y-4">
          <div className="mt-8 bg-white w-full rounded-lg flex">
            <div className="mr-2 hidden lg:flex">
              <img
                src="https://play-lh.googleusercontent.com/jtTsOrqubY-bKPKe-mdQElyd33A8HoHO1iLVlSujT-MiUx-FIDCTfKah_oKnAs9JgA"
                className="lg:w-[250px] lg:h-[150px] 2xl:w-[150px] 2xl:h-[150px] rounded-tl-lg rounded-bl-lg"
              />
            </div>
            <div className="p-2 ml-4 justify-center flex flex-col 2xl:justify-start 2xl:mt-2 ">
              <h2 className="font-bold text-[21px]">Yemek.Club & IYZICO</h2>
              <p className="font-medium text-gray-400">
                <span className="text-main font-semiBold">Yemek.Club</span>,
                online ödemelerde Iyzico ile işbirliği yaparak güvenli ödeme
                altyapısı sunmanın yanı sıra, üye işletmelere{" "}
                <span className="text-main font-semiBold">
                  ertesi gün ödeme
                </span>{" "}
                garantisi sağlıyor. Bu işbirliği sayesinde üye işletmelerin
                nakit akışını hızlandırarak işlerini kolaylaştıran bir çözüm
                sunar.{" "}
              </p>
            </div>
          </div>

          <div className="mt-8 bg-white w-full rounded-lg flex">
            <div className="mr-2 hidden lg:flex">
              <img
                src="https://www.kolayrestoran.com/wp-content/uploads/2020/09/post2.3.jpg"
                className="lg:w-[250px] lg:h-[150px] 2xl:w-[150px] 2xl:h-[150px] rounded-tl-lg rounded-bl-lg"
              />
            </div>
            <div className="p-2 ml-4 justify-center flex flex-col ">
              <h2 className="font-bold text-[21px]">
                Yemek.Club QR Menü Hizmeti
              </h2>
              <p className="font-medium text-gray-400">
                <span className="text-main font-semiBold">Yemek.Club</span> üye
                işletmeler için{" "}
                <span className="text-main font-semiBold">ücretsiz</span> QR
                Menü hizmetini sunmaya başladı! Yemeklerinizi tekrar eklemenize
                gerek yok.{" "}
                <span className="text-main font-semiBold">Yemek.Club</span>,
                restoranınıza eklediğiniz yemekleri kullanarak sizin için QR
                menü oluşturuyor.
              </p>
              <div className="mt-4">
                <button
                  onClick={() => setQrOpen(!qrOpen)}
                  className="bg-main p-2 rounded-md text-white font-bold"
                >
                  Hemen Dene
                </button>
              </div>
            </div>
          </div>

          <div className="mt-8 bg-white w-full rounded-lg flex">
            <div className="mr-2 hidden lg:flex">
              <img
                src="https://storage.googleapis.com/production-eng/1/2021/04/ClientX.png"
                className="lg:w-[250px] lg:h-[150px] 2xl:w-[150px] 2xl:h-[150px] rounded-tl-lg rounded-bl-lg"
              />
            </div>
            <div className="p-2 ml-4 justify-center flex flex-col ">
              <h2 className="font-bold text-[21px]">
                Yemek.Club Restaurant Masaüstü Uygulaması
              </h2>
              <p className="font-medium text-gray-400">
                <span className="text-main font-semiBold">Yemek.Club</span> üye
                işletmeler için{" "}
                <span className="text-main font-semiBold">ücretsiz</span>{" "}
                masaüstü yönetim paneli sunuyor. Siparişlerinizi masaüstü
                uygulamamızı yükleyerek kolayca takip edebilirsiniz.
              </p>
              <div className="mt-4 flex gap-x-2">
                <a
                  href="/application/YemekClubRestaurant.exe"
                  className="bg-main p-2 rounded-md text-white font-bold flex gap-x-1"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Windows_icon_logo.png"
                    className="w-[25px]"
                  />
                  <p>Windows için indir</p>
                </a>
                <a
                  href="/application/YemekClubRestaurant.dmg"
                  className="bg-main p-2 rounded-md text-white font-bold flex gap-x-1"
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Apple_logo_white.svg/800px-Apple_logo_white.svg.png"
                    className="w-[20px]"
                  />
                  <p>MacOS için indir</p>
                </a>
              </div>
            </div>
          </div>

          <div className="mt-8 bg-white w-full rounded-lg flex">
            <div className="mr-2 hidden lg:flex">
              <img
                src="https://www.seguetech.com/wp-content/uploads/2015/04/segue-blog-Mobile-Application-Testing.png"
                className="lg:w-[250px] lg:h-[150px] 2xl:w-[150px] 2xl:h-[150px] rounded-tl-lg rounded-bl-lg"
              />
            </div>
            <div className="p-2 ml-4 justify-center flex flex-col ">
              <h2 className="font-bold text-[21px]">
                Yemek.Club Restaurant Mobil Uygulaması
              </h2>
              <p className="font-medium text-gray-400">
                <span className="text-main font-semiBold">Yemek.Club</span> üye
                işletmeler için{" "}
                <span className="text-main font-semiBold">ücretsiz</span> mobil
                yönetim paneli sunuyor. Siparişlerinizi mobil uygulamamızı
                yükleyerek kolayca takip edebilirsiniz.
              </p>
              <div className="mt-4 flex gap-x-2">
                <a href="https://play.google.com/store/apps/details?id=com.loc.ryemekclub">
                  <img
                    src="https://cihanbuyukakkas.com/wp-content/uploads/2021/04/Google-Play-hemen-indir-button-logo-icon-transparan-PNG-gorseli.png"
                    className="w-[120px]"
                  />
                </a>
                <a href="https://apps.apple.com/jm/app/r-yemek-club/id6449799705">
                  <img
                    src="https://leasingsolutions.bnpparibas.com.tr/wp-content/uploads/sites/9/2021/11/app-store-indir.png"
                    className="w-[120px]"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={qrOpen}
          onClose={() => setQrOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col items-center justify-center">
              <QRCode
                value={`https://qr.yemek.club/${MainStore.user._id}`}
                logoImage="/assets/image/logo/logo.webp"
                logoWidth={45}
                logoHeight={45}
                removeQrCodeBehindLogo={true}
              />
            </div>
          </Box>
        </Modal>
      </div>
    </AuthLayout>
  );
}

export default Page;
