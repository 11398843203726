import HeadingText from '../../components/Text/HeadingText';
import React, {useEffect, useState} from 'react';
import {DataGrid, GridRowsProp, GridColDef, GridRowParams, MuiEvent, GridRowModel} from '@mui/x-data-grid';
import {trTR} from '@mui/x-data-grid/locales';
import ActionIcon from '../../components/Icon/Order/ActionIcon';
import {Tooltip} from '@mui/material';
import ConfirmIcon from '../../components/Icon/Order/ConfirmIcon';
import RejectIcon from '../../components/Icon/Order/RejectIcon';
import ShowIcon from '../../components/Icon/Order/ShowIcon';
import axios from 'axios';
import MainStore from '../../store/MainStore';
import moment from 'moment';
import AuthLayout from '../../layout/AuthLayout';
import toast from 'react-hot-toast';
import {Socket} from 'socket.io-client';
import useSound from 'use-sound';
function Page({socket}: {socket: Socket}) {
  const columns: GridColDef[] = [
    {
      field: 'col1',
      headerName: 'Sipariş ID',
      minWidth: 150,
      flex: 0.2,
      renderCell: (params) => (
        <div
          className={`${
            params.row.orderStatus == 'Beklemede' && 'text-black'
          } text-[#3E4954] font-semiBold text-[16px]`}
        >
          {params.row._id}
        </div>
      ),
    },
    {
      field: 'orderDate',
      filterable: false,
      headerName: 'Sipariş Tarihi',
      minWidth: 210,
      flex: 0.2,
      renderCell: (params) => (
        <div
          className={`${params.row.orderStatus == 'Beklemede' && 'text-black'} text-[#3E4954] font-semiBold`}
        >{`${moment(params.row.created_at).format('DD/MM/YYYY')} ${moment(params.row.created_at).format(
          'HH:mm'
        )}`}</div>
      ),
    },
    {
      field: 'customerData',
      filterable: false,
      headerName: 'Müşteri Bilgisi',
      minWidth: 240,
      flex: 0.3,
      renderCell: (params) => (
        <div className={`${params.row.orderStatus == 'Beklemede' && 'text-black'} text-[#3E4954] font-semiBold`}>
          {params.row.customerData.customerName}
        </div>
      ),
    },
    {
      field: 'totalPaid',
      filterable: false,
      headerName: 'Toplam',
      minWidth: 110,
      flex: 0.2,
      renderCell: (params) => (
        <div className={`${params.row.orderStatus == 'Beklemede' && 'text-black'} text-[#3E4954] font-semiBold`}>
          ₺{params.row.totalPaid}
        </div>
      ),
    },
    {
      field: 'orderStatus',
      headerName: 'Durum',
      minWidth: 150,
      flex: 0.2,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Teslim Edildi', 'Yolda', 'Hazırlanıyor', 'Beklemede', 'İptal Edildi'],

      renderCell: (params) => (
        <div
          className={`w-fit ${
            params.row.orderStatus == 'İptal Edildi'
              ? 'text-main bg-main/5  px-[16px] rounded-md'
              : params.row.orderStatus == 'Beklemede'
              ? 'text-[#FF6D4D] bg-[#FF6D4D]/5 px-[16px] rounded-md'
              : params.row.orderStatus == 'Teslim Edildi'
              ? 'text-[#2BC155] bg-[#2BC155]/5  px-[16px] rounded-md'
              : params.row.orderStatus == 'Yolda'
              ? 'text-[#2F4CDD] bg-[#2F4CDD]/5  px-[16px] rounded-md'
              : 'text-[#3E4954] bg-[#3E4954]/5  px-[14px] rounded-md'
          } font-bold`}
        >
          {params.row.orderStatus}
        </div>
      ),
    },
    {
      field: 'col6',
      headerName: 'İşlem',
      width: 10,
      flex: 0.05,
      align: 'center',
      renderCell: (params) => (
        <a
          href={`/siparisler/detay/${params.row._id}`}
          className='flex gap-x-2 pt-4'
        >
          <ShowIcon className='cursor-pointer' />
        </a>
      ),
      renderHeader: (params) => <></>,
      filterable: false,
      sortable: false,
      hideSortIcons: false,
      disableColumnMenu: true,
    },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [rowCount, setRowCount] = useState<number>(0);
  const [rows, setRows] = useState<GridRowsProp | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  socket.on('connect', () => {
    socket.emit('subscribe', 'newOrder');
  });

  socket.on('newOrder', (data) => {
    if (data.rId == MainStore.user._id) {
      setIsRefresh(!isRefresh);
    }
  });

  const sound = new Audio('https://restaurant.yemek.club/assets/audio/notification.mp3');

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://api.yemek.club/restaurant/api/getMyOrders?page=${paginationModel.page}&limit=${paginationModel.pageSize}`,
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        console.log(res.data);
        setRows(res.data.allOrder);
        setRowCount(res.data.totalPages);
        setIsLoading(false);
      });
  }, [paginationModel, isRefresh]);

  if (isLoading) {
    return null;
  }

  const handleRowUpdate = async (updatedRow: GridRowModel, originalRow: GridRowModel): Promise<GridRowModel | null> => {
    try {
      const res = await axios.post(
        `https://api.yemek.club/restaurant/api/changeOrderStatus/${updatedRow._id}`,
        {orderStatus: updatedRow.orderStatus},
        {headers: {Authorization: MainStore.token}}
      );

      if (res.data.success) {
        return updatedRow;
      } else {
        toast.error(res.data.error);
        return originalRow;
      }
    } catch (error) {
      console.error('Error updating row:', error);
      // You can handle error cases here, for example:
      toast.error('Error updating row');
      return originalRow; // Return original row on error
    }
  };

  return (
    <AuthLayout>
      <div>
        <div
          onClick={() => sound.play()}
          className='mt-8'
        >
          <HeadingText
            title='Siparişlerim'
            subTitle={'Gelen siparişlerinizi anlık takip edebilirsiniz!'}
          />
        </div>

        <div className='mt-8 w-full  bg-white p-8 rounded-[20px]'>
          <div style={{width: '100%'}}>
            <DataGrid
              onProcessRowUpdateError={(error) => console.log(error)}
              processRowUpdate={handleRowUpdate}
              rowCount={rowCount}
              loading={isLoading}
              paginationMode='server'
              pageSizeOptions={[10, 20, 30]}
              getRowId={(row) => row._id}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              rows={rows}
              columns={columns}
              sx={{
                border: 0,
                borderColor: '#D3D6E4',
                '.MuiDataGrid-columnHeader': {
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: 18,
                },
                '.MuiDataGrid-cell': {border: 0},
                '.MuiDataGrid-footerCell': {paddingBottom: 10},
                '.MuiDataGrid-footerContainer': {border: 0},
              }}
              localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Page;

/*
<div className={`w-fit h-[20px] ${params.row.orderStatus == 'İptal Edildi' ? 'text-main bg-main/5 py-[5px] px-[16px] rounded-md' : params.row.orderStatus == 'Beklemede' ? 'text-[#FF6D4D] bg-[#FF6D4D]/5 py-[10px] px-[16px] rounded-md' : params.row.orderStatus == 'Teslim Edildi' ? 'text-[#2BC155] bg-[#2BC155]/5 py-[10px] px-[16px] rounded-md' : params.row.orderStatus == 'Yolda' ? 'text-[#2F4CDD] bg-[#2F4CDD]/5 py-[10px] px-[16px] rounded-md' : 'text-[#3E4954]'} font-bold`}>{params.row.orderStatus}</div>
*/
