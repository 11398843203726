import {observer} from 'mobx-react';
import StatsCard from '../../components/Cards/StatsCard';
import RevenueTypeChart from '../../components/Chart/RevenueTypeChart';
import OrderStatusSection from '../../components/Home/OrderStatusSection';
import PopularMenuSection from '../../components/Home/PopularMenuSection';
import CardHeadingText from '../../components/Text/CardHeadingText';
import HeadingText from '../../components/Text/HeadingText';
import {StatsCardList} from '../../database/StatsCardList';
import AuthLayout from '../../layout/AuthLayout';
import MainStore from '../../store/MainStore';
import useSound from 'use-sound';
import {useEffect, useState} from 'react';
import axios from 'axios';

type DashboardDataProps = {
  stats: {
    allOrder: {
      stats: number;
      subInfo: number;
    };
    waitingOrder: {
      stats: number;
      subInfo: number;
    };
    totalRevenues: {
      stats: number;
    };
    allFoodCount: {
      stats: number;
    };
  };
  popularFood: [];
};

function DashboardPage() {
  const [data, setData] = useState<DashboardDataProps | null>(null);
  const [play] = useSound('https://restaurant.yemek.club/assets/audio/notification.mp3');

  const handlePlay = () => {
    console.log(window.AudioContext);
  };

  useEffect(() => {
    axios
      .get('https://api.yemek.club/restaurant/api/getDashboard', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setData(res.data);
      });
  }, []);
  return (
    <>
      <AuthLayout>
        <div>
          <div
            className='mt-8'
            onClick={handlePlay}
          >
            <HeadingText
              title='Kontrol Merkezi'
              subTitle={'İstatistiklerinizi ve verilerinizi canlı takip edebilirsiniz!'}
            />
          </div>

          {/* Stats Area */}
          <div className='flex flex-col md:flex-row md:justify-center lg:flex-nowrap justify-between mt-8 flex-wrap gap-y-4'>
            <StatsCard
              Icon={StatsCardList[0].Icon}
              stats={data?.stats?.allOrder?.stats ? data.stats.allOrder.stats : 0}
              title={StatsCardList[0].title}
              href={StatsCardList[0].href}
              subInfo={data?.stats?.allOrder?.subInfo ? data.stats.allOrder.subInfo : 1}
            />

            <StatsCard
              Icon={StatsCardList[1].Icon}
              stats={data?.stats?.waitingOrder?.stats ? data.stats.waitingOrder.stats : 0}
              title={StatsCardList[1].title}
              href={StatsCardList[1].href}
              subInfo={data?.stats?.waitingOrder?.subInfo ? data.stats.waitingOrder.subInfo : 1}
            />

            <StatsCard
              Icon={StatsCardList[2].Icon}
              stats={data?.stats?.totalRevenues?.stats ? data.stats.totalRevenues.stats : 0}
              title={StatsCardList[2].title}
              href={StatsCardList[2].href}
            />

            <StatsCard
              Icon={StatsCardList[3].Icon}
              stats={data?.stats?.allFoodCount?.stats ? data.stats.allFoodCount.stats : 0}
              title={StatsCardList[3].title}
              href={StatsCardList[3].href}
            />
          </div>

          <div className='flex flex-col 2xl:flex-row justify-between mt-8 '>
            <div className='2xl:w-6/12 w-full  bg-white p-8 rounded-[20px]'>
              <OrderStatusSection />
            </div>

            <div className='2xl:w-6/12 w-full   bg-white p-8 rounded-[20px] 2xl:ml-4'>
              <PopularMenuSection data={data?.popularFood ? data.popularFood : []} />
            </div>
          </div>

          <div>
            <div className=' w-full mt-8  bg-white p-8 rounded-[20px] hidden lg:flex lg:flex-col'>
              <div className='flex'>
                <div>
                  <CardHeadingText
                    title={'Gelir Tipi'}
                    subTitle={'Son 7 gün içerisinde aldığınız siparişlerin tipini kontrol edebilirsiniz.'}
                  />
                </div>
                <div></div>
              </div>

              <div className='flex flex-col lg:flex-row '>
                <div className='mt-12 lg:w-3/12 w-full '>
                  <RevenueTypeChart />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default observer(DashboardPage);
