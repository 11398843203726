import CardHeadingText from '../../components/Text/CardHeadingText';
import HeadingText from '../../components/Text/HeadingText';
import MainStore from '../../store/MainStore';
import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material';
import axios from 'axios';
import React, {useState} from 'react';
import toast from 'react-hot-toast';
import {MdVisibility, MdVisibilityOff} from 'react-icons/md';
import AuthLayout from '../../layout/AuthLayout';

function Page() {
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [renewPassword, setReNewPassword] = useState<string>('');
  const [showPassword, setShowPassowrd] = useState({
    oldPassword: false,
    newPassword: false,
    renewPassword: false,
  });

  const handleClick = () => {
    if (newPassword == renewPassword) {
      axios
        .post(
          'https://api.yemek.club/restaurant/api/changeMyPassword',
          {oldPassword, newPassword},
          {headers: {Authorization: `Bearer ${MainStore.token}`}}
        )
        .then((res) => {
          if (res.data.success) {
            toast.success('Şifreniz başarıyla değiştirilmiştir!');
          } else if (res.data.error) {
            toast.error(res.data.error);
          } else {
            toast.error('Beklenmedik bir hata oluştu lütfen daha sonra tekrar deneyiniz.');
          }
        })
        .catch((e) => {
          toast.error('Beklenmedik bir hata oluştu lütfen daha sonra tekrar deneyiniz.');
        });
    } else {
      toast.error('Yeni Şifre ve Tekrar Yeni Şifre alanı uyuşmuyor.');
    }
  };

  return (
    <AuthLayout>
      <div>
        <div className='mt-8'>
          <HeadingText
            title='Güvenlik Ayarları'
            subTitle={'Hesabınızın güvenlik ayarlarını yönetin.'}
          />
        </div>

        <div className='mt-8 w-full bg-white p-4'>
          <div>
            <CardHeadingText
              title='Şifre İşlemleri'
              subTitle={' '}
            />
          </div>
          <div className='mt-8 flex flex-col gap-y-2 max-w-[600px]'>
            <div>
              <FormControl
                fullWidth
                className='bg-[#F9F9F9]'
              >
                <InputLabel
                  className='font-semiBold'
                  htmlFor='outlined-adornment-amount'
                >
                  Eski Şifreniz
                </InputLabel>
                <OutlinedInput
                  id='Eski Şifre'
                  label='Eski Şifre'
                  className='font-medium text-[17px]'
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  type={showPassword.oldPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          const tempObject = {...showPassword};
                          tempObject.oldPassword = !tempObject.oldPassword;
                          setShowPassowrd(tempObject);
                        }}
                      >
                        {showPassword.oldPassword ? <MdVisibility /> : <MdVisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div>
              <FormControl
                fullWidth
                className='bg-[#F9F9F9] '
              >
                <InputLabel
                  className='font-semiBold'
                  htmlFor='outlined-adornment-amount'
                >
                  Yeni Şifreniz
                </InputLabel>
                <OutlinedInput
                  id='Yeni Şifre'
                  label='Yeni Şifre'
                  className='font-medium text-[17px]'
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type={showPassword.newPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => {
                          const tempObject = {...showPassword};
                          tempObject.newPassword = !tempObject.newPassword;
                          setShowPassowrd(tempObject);
                        }}
                      >
                        {showPassword.newPassword ? <MdVisibility /> : <MdVisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <FormControl
              fullWidth
              className='bg-[#F9F9F9] '
            >
              <InputLabel
                className='font-semiBold'
                htmlFor='outlined-adornment-amount'
              >
                Yeni Şifreniz Tekrar
              </InputLabel>
              <OutlinedInput
                id='Yeni Şifre Tekrar'
                label='Yeni Şifre Tekrar'
                className='font-medium text-[17px]'
                value={renewPassword}
                onChange={(e) => setReNewPassword(e.target.value)}
                type={showPassword.renewPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        const tempObject = {...showPassword};
                        tempObject.renewPassword = !tempObject.renewPassword;
                        setShowPassowrd(tempObject);
                      }}
                    >
                      {showPassword.renewPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className='mt-4'>
            <button
              onClick={handleClick}
              className='bg-main p-3 rounded-md text-white font-bold'
            >
              Değişiklikleri Kaydet
            </button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Page;
