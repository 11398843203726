import { BsArrowLeftShort } from "react-icons/bs";
import {
  MdOutlineDashboard,
  MdMenuBook,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { IoFastFood, IoMapSharp } from "react-icons/io5";
import { FaBox, FaComment, FaGift, FaShieldAlt } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { GiMeal } from "react-icons/gi";
import React, { useState } from "react";
import { Badge } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { menuList } from "../../database/menuList";



const theme = createTheme({
  palette: {
    primary: {
      main: "#FF0100",
    },
  },
});

function Sidebar() {
  const [open, setOpen] = useState(false);
  const urlPathname = `/${window.location.href.split('/')[3]}`
  

  return (
    <div>
      <div
        className={`bg-white h-screen p-5 pt-8 ${
          open ? "w-72" : "w-20"
        }  relative duration-300`}
      >
        <BsArrowLeftShort
          className={`text-main bg-white text-3xl rounded-full absolute -right-5 top-9 border cursor-pointer ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
        <div className={`${open && "mb-14"}`}>
          <img
            alt="logo"
            src="/assets/image/logo/logo.webp"
            className={`w-[60px] cursor-pointer block float-left mr-2 duration-300 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-main origin-left lg:text-2xl font-black 2xl:text-3xl duration-300 pt-2 ${
              !open && "scale-0"
            }`}
          >
            YEMEK.CLUB
          </h1>
        </div>

        {menuList.map(
          ({ name, Icon, pathname, spacing, badge, badgeContent }, index) => (
            <a
              href={pathname}
              key={index}
              className={`flex cursor-pointer hover:bg-[#F9F9F9] items-center rounded-md ${
                urlPathname === pathname && "bg-main"
              } ${
                !open && urlPathname !== pathname && "bg-[#F9F9F9]"
              } mt-4 py-2  ${!open ? "px-2.5" : "px-3"}`}
            >
              {badge && badgeContent && !open && urlPathname !== pathname ? (
                <ThemeProvider theme={theme}>
                  <Badge badgeContent={badgeContent} color="primary">
                    <Icon
                      className={`${
                        urlPathname === pathname
                          ? "text-white"
                          : "text-black/80"
                      } block text-xl float-left cursor-pointer ${
                        open && "mr-2"
                      }`}
                    />
                  </Badge>
                </ThemeProvider>
              ) : (
                <Icon
                  className={`${
                    urlPathname === pathname ? "text-white" : "text-black/80"
                  } block text-xl float-left cursor-pointer ${open && "mr-2"}`}
                />
              )}
              <h3
                className={`text-base bg-transparent w-full ${
                  urlPathname === pathname ? "text-white" : "text-black/80"
                } ${!open && "hidden"}`}
              >
                {name}
              </h3>
            </a>
          )
        )}
      </div>
    </div>
  );
}

export default Sidebar;
