import React from 'react'
import CardHeadingText from '../Text/CardHeadingText'
import { HomeOrderStatsList } from '../../database/HomeOrderStatsList'
import OrderStatsChart from '../Chart/OrderStatsChart'
import OrderStatsProgress from '../Progress/OrderStatsProgress'

function OrderStatusSection() {
    return (
        <div>
            <div className='flex'>
                <div>
                    <CardHeadingText title={'Sipariş Durumu'} subTitle={'Sipariş istatistiklerinizi canlı bir şekilde görüntüleyebilirsiniz.'} />
                </div>
                <div>

                </div>
            </div>
            <div className='flex flex-col lg:flex-row '>
                <div className='mt-12 lg:w-3/12'>
                    <OrderStatsChart />
                </div>
                <div className='mt-8 w-full lg:w-9/12 self-center  flex flex-col gap-y-3 ml-24' >
                    {HomeOrderStatsList.map(({ color, title, value, stats }, index) =>
                        <OrderStatsProgress key={index} color={index == 0 ? 'primary' : index == 1 ? 'secondary' : index == 2 ? 'success' : 'error'} title={title} value={value} stats={stats} />
                    )}

                </div>
            </div>
        </div>
    )
}

export default OrderStatusSection