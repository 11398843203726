import React, {useState} from 'react';
import {menuList} from '../../database/menuList';
import {Badge, Switch, ThemeProvider, Tooltip, createTheme} from '@mui/material';
import MainStore from '../../store/MainStore';
import {FaChevronDown, FaInfo, FaBell, FaBellSlash} from 'react-icons/fa6';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {observer} from 'mobx-react';
import axios from 'axios';
import toast from 'react-hot-toast';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0100',
    },
  },
});
function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(MainStore.user.visible);
  const [notificationStatus, setNotificationStatus] = useState<boolean>(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleOnChangeSwitch = async () => {
    axios
      .post(
        'https://api.yemek.club/restaurant/api/changeVisible',
        {visible: !visible},
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        if (res.data.success) {
          setVisible(!visible);
          toast.success(`Restoran durumunuz başarıyla ${visible ? 'Kapalı' : 'Açık'} olarak değiştirildi.`);
        } else {
          toast.error('Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.');
        }
      })
      .catch((e) => {
        toast.error('Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.');
      });
  };

  const urlPathname = `/${window.location.href.split('/')[3]}`;
  return (
    <div className='bg-white px-2 md:pb-2 pb-4 rounded-lg flex'>
      <div className='md:hidden flex justify-between w-full '>
        {menuList.map(({name, Icon, pathname, spacing, badge, badgeContent}, index) => (
          <a
            href={pathname}
            key={index}
            className={`flex cursor-pointer hover:bg-[#F9F9F9] items-center rounded-md ${
              urlPathname === pathname && 'bg-main'
            } ${urlPathname !== pathname && 'bg-[#F9F9F9]'} mt-4 py-2  ${'px-2'}`}
          >
            {badge && badgeContent && urlPathname !== pathname ? (
              <ThemeProvider theme={theme}>
                <Badge
                  badgeContent={badgeContent}
                  color='primary'
                >
                  <Icon
                    className={`${
                      urlPathname === pathname ? 'text-white' : 'text-black/80'
                    } block text-xl float-left cursor-pointer`}
                  />
                </Badge>
              </ThemeProvider>
            ) : (
              <Icon
                className={`${
                  urlPathname === pathname ? 'text-white' : 'text-black/80'
                } block text-xl float-left cursor-pointer `}
              />
            )}
          </a>
        ))}
      </div>
      <div className='md:justify-between w-full items-center hidden md:flex'>
        <div className='w-4/12'>
          <h1 className={`text-main origin-left lg:text-2xl font-black 2xl:text-3xl duration-300 pt-2 ml-2`}>
            YEMEK.CLUB
          </h1>
        </div>

        <div className='flex w-full justify-between'>
          <div></div>

          <div className='flex gap-x-12 items-center'>
            <div>
              <div className='flex gap-x-2 items-center'>
                <h3 className='font-semiBold text-[15px]'>Bildirim Sesleri</h3>
                <Tooltip
                  title={`Tarayıcı politikalarından dolayı her sayfayı yenilediğinizde zil butonuna basarak bildirim seslerini açmanız gerekiyor.`}
                >
                  <div className='bg-[#d1d1d1] w-fit p-1 rounded-full h-fit text-[9px]'>
                    <FaInfo className='text-gray-600' />
                  </div>
                </Tooltip>
              </div>

              <div className='flex items-center'>
                <p
                  className={`text-[12px] ${!notificationStatus ? 'text-main font-bold' : 'text-black/90 font-medium'}`}
                >
                  Kapalı
                </p>
                <ThemeProvider theme={theme}>
                  <Switch
                    checked={notificationStatus}
                    color='primary'
                    onChange={() => {
                      if (!notificationStatus) {
                        toast.success('Bildirim sesleri başarıyla açılmıştır!');
                        setNotificationStatus(true);
                      }
                    }}
                  />
                </ThemeProvider>
                <p
                  className={`text-[12px] ${notificationStatus ? 'text-main font-bold' : 'text-black/90 font-medium'}`}
                >
                  Açık
                </p>
              </div>
            </div>

            <div>
              <h3 className='font-semiBold text-[15px]'>Restoran Durumu</h3>
              <div className='flex items-center'>
                <p className={`text-[12px] ${!visible ? 'text-main font-bold' : 'text-black/90 font-medium'}`}>
                  Kapalı
                </p>
                <ThemeProvider theme={theme}>
                  <Switch
                    checked={visible}
                    color='primary'
                    onChange={handleOnChangeSwitch}
                  />
                </ThemeProvider>
                <p className={`text-[12px] ${visible ? 'text-main font-bold' : 'text-black/90 font-medium'}`}>Açık</p>
              </div>
            </div>

            <button
              onClick={handleClick}
              className='flex items-center gap-x-3 cursor-pointer'
            >
              <div className='bg-main w-[35px] h-[35px] flex flex-col items-center justify-center rounded-md'>
                <p className=' font-bold text-[30px] text-white'>R</p>
              </div>
              <div>
                <h3 className=' font-semiBold text-[14px]'>{MainStore.user.restaurantName}</h3>
                <p className='font-medium text-[12px] text-black/60'>{`${MainStore?.user?.province}/${MainStore?.user?.district}`}</p>
              </div>
              <div className='text-black/80 text-[13px]'>
                <FaChevronDown />
              </div>
            </button>
          </div>
        </div>
      </div>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className=' font-medium'
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => (window.location.href = '/ayarlar')}>Ayarlarım</MenuItem>
        <MenuItem onClick={() => (window.location.href = '/destek')}>Yardım Merkezi</MenuItem>
        <MenuItem onClick={() => MainStore.logout()}>Çıkış Yap</MenuItem>
      </Menu>
    </div>
  );
}

export default observer(Header);
