import React, {useState, useEffect} from 'react';
import HeadingText from '../../components/Text/HeadingText';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
  createTheme,
  ThemeProvider,
  Switch,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import {Box} from '@mui/material';
import AuthLayout from '../../layout/AuthLayout';
import axios from 'axios';
import MainStore from '../../store/MainStore';
import toast from 'react-hot-toast';
import {FaPlus, FaX} from 'react-icons/fa6';
import {FaSearch} from 'react-icons/fa';
import {CheckBox} from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF0100',
    },
  },
});

type OptionalDataProps = {
  _id: string;
  name: string;
  active: boolean;
  rId: string;
  total: number;
};

type TemplateOptionalProps = {
  _id?: string;
  name: string;
  price: number | string;
  optionalID: string;
  active: boolean;
};

type TemplateProps = {
  _id: string;
  title: string;
  value: TemplateOptionalProps[];
  rId: string;
  active: boolean;
};

function RenderOpsiyonlar({
  isRefresh,
  optionalData,
  setOptionalData,
  setIsRefresh,
}: {
  isRefresh: boolean;
  optionalData: OptionalDataProps[] | null;
  setOptionalData: React.Dispatch<React.SetStateAction<any>>;
  setIsRefresh: React.Dispatch<React.SetStateAction<any>>;
}) {
  const changeOptionalActive = async (index: number) => {
    if (optionalData) {
      const tempObject = [...optionalData];
      try {
        const res = await axios.get(
          `https://api.yemek.club/restaurant/api/changeOptionalActive/${tempObject[index]._id}`,
          {headers: {Authorization: `Bearer ${MainStore.token}`}}
        );
        if (res.data.success) {
          toast.success(`${tempObject[index].name} opsiyonunun durumu başarıyla değiştirildi.`);
          tempObject[index].active = !tempObject[index].active;
          setOptionalData(tempObject);
          setIsRefresh(!isRefresh);
        }
      } catch (error) {
        console.error('Error changing optional active state:', error);
      }
    }
  };

  return (
    <div className='mt-8 bg-white rounded-lg p-8 flex flex-col gap-y-4'>
      {optionalData?.map((item, index) => (
        <div
          key={index}
          className='flex justify-between items-center'
        >
          <div>
            <h4 className='font-medium'>
              {item.name}{' '}
              <span className=' text-[12px]'>
                (
                <span className={`pr-2 ${item.active ? 'text-main' : 'text-black/60'}`}>
                  {' '}
                  Açık : {item.active ? item.total : 0}
                </span>{' '}
                <span className={`${!item.active ? 'text-main' : 'text-black/60'}`}>
                  Kapalı: {!item.active ? item.total : 0}{' '}
                </span>
                )
              </span>
            </h4>
          </div>
          <div className='flex gap-x-2'>
            <ThemeProvider theme={theme}>
              <Switch
                onChange={() => {
                  changeOptionalActive(index);
                }}
                checked={item.active}
              />
            </ThemeProvider>
          </div>
        </div>
      ))}
    </div>
  );
}

function RenderSablonlar({
  templateData,
  setTemplateData,
  isRefresh,
}: {
  templateData: TemplateProps[] | null;
  setTemplateData: React.Dispatch<React.SetStateAction<any>>;
  isRefresh: boolean;
}) {
  const changeTemplatesActive = async (id: string) => {
    axios
      .get(`https://api.yemek.club/restaurant/api/changeTemplateActive/${id}`, {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        console.log(res.data);
      });
  };
  return (
    <div className='mt-8'>
      <div className='flex gap-x-3'>
        {templateData?.map(({_id, active, title, value}, index) => (
          <div
            key={index}
            className='bg-white p-4 rounded-lg w-3/12'
          >
            <div className='flex items-center justify-between border-b-[1px] pb-2'>
              <div>
                <h2 className='font-semiBold text-[15px]'>{title}</h2>
              </div>
              <div>
                <Tooltip title={active ? 'Aktif' : 'Pasif'}>
                  <div className={`w-3 h-3 ${active ? 'bg-green-600' : 'bg-main'} rounded-full`} />
                </Tooltip>
              </div>
            </div>
            <div className='mt-4'>
              {value.map((child, idx) => (
                <div
                  key={idx}
                  className='flex justify-between'
                >
                  <div>
                    <p className='font-medium text-gray-600 text-[14px]'>
                      {`${child.name}`}{' '}
                      <span className='text-[12px] text-gray-500'>{`(${
                        typeof child.price == 'string'
                          ? parseFloat(child.price) > 0
                            ? '+'
                            : ''
                          : child.price > 0
                          ? '+'
                          : ''
                      }${child.price} TL)`}</span>
                    </p>
                  </div>
                  <div>
                    <Tooltip title={child.active ? 'Aktif' : 'Pasif'}>
                      <div className={`w-3 h-3 ${child.active ? 'bg-green-600' : 'bg-main'} rounded-full`} />
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
            <div className='mt-8 flex justify-center'>
              <button
                onClick={() => changeTemplatesActive(_id)}
                className={`${active ? 'bg-green-600' : 'bg-main'} p-2 rounded-md text-white font-bold text-[14px]`}
              >
                {active ? 'Şablonu Kapat' : 'Şablonu Aç'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function Page() {
  const [selectedTab, setSelectedTab] = useState<'opsiyonlar' | 'sablonlar'>('opsiyonlar');
  const [opsiyonlarModalOpen, setOpsiyonlarModalOpen] = useState(false);
  const [sablonlarModalOpen, setSablonlarModalOpen] = useState(false);
  const [inputFields, setInputFields] = useState([{id: 1, value: ''}]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [optionalData, setOptionalData] = useState<OptionalDataProps[] | null>(null);
  const [templateData, setTemplateData] = useState<TemplateProps[] | null>(null);
  const [templatesValue, setTemplatesValues] = useState<TemplateOptionalProps[] | []>([]);
  const [title, setTitle] = useState<string>('');
  const [step, setStep] = useState<1 | 2>(1);
  useEffect(() => {
    axios
      .get('https://api.yemek.club/restaurant/api/getMyOptional', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setOptionalData(res.data);
      })
      .catch((error) => {
        console.error('Error fetching optional data:', error);
      });

    axios
      .get('https://api.yemek.club/restaurant/api/getTemplates', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setTemplateData(res.data);
      })
      .catch((error) => {
        console.error('Error fetching optional data:', error);
      });
  }, [isRefresh]);

  const handleAddInput = () => {
    const newInputFields = [...inputFields];
    const newId = inputFields.length + 1;
    newInputFields.push({id: newId, value: ''});
    setInputFields(newInputFields);
  };

  const handleInputChange = (id: number, value: string) => {
    const updatedInputFields = inputFields.map((field) => {
      if (field.id === id) {
        return {...field, value};
      }
      return field;
    });
    setInputFields(updatedInputFields);
  };

  const checkChecked = (option: OptionalDataProps) => {
    const validate = templatesValue?.find((item) => item.optionalID == option._id);
    if (validate) {
      return true;
    } else {
      return false;
    }
  };

  const getOptionValue = (option: OptionalDataProps) => {
    const validate = templatesValue?.find((item) => item._id == option._id);
    if (validate) {
      return validate;
    } else {
      return false;
    }
  };

  const handleSubmit = () => {
    const optionalList = inputFields.map((field) => field.value);
    axios
      .post(
        'https://api.yemek.club/restaurant/api/addOptional',
        {optionalList},
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        if (res.data.success) {
          if (res.data.addedOption === 0) {
            toast.error(`Aynı isime sahip ${res.data.existsOption.length} opsiyon eklenemedi`);
          } else if (res.data.existsOption.length > 0) {
            toast.success(
              `${res.data.addedOption} adet opsiyon başarıyla eklendi. Aynı isime sahip ${res.data.existsOption.length} adet opsiyon eklenemedi.`
            );
            setIsRefresh(!isRefresh);
          } else {
            toast.success(`${res.data.addedOption} adet opsiyon başarıyla eklendi.`);
            setIsRefresh(!isRefresh);
          }
        } else {
          toast.error('Opsiyon eklenirken bir hata oldu.');
        }
      })
      .catch((e) => {
        toast.error('Opsiyon eklenirken bir hata oldu.');
      });
    setOpsiyonlarModalOpen(false);
  };

  return (
    <AuthLayout>
      <div>
        <div className='mt-8 flex justify-between'>
          <HeadingText
            title={selectedTab === 'opsiyonlar' ? 'Opsiyonlar' : 'Şablonlar'}
            subTitle={
              selectedTab === 'opsiyonlar'
                ? 'Ürünleriniz için opsiyon ekleyip yönetebilirsiniz.'
                : 'Eklediğiniz opsiyonlar ile şablon oluşturabilirsiniz !'
            }
          />
          <button
            onClick={() => (selectedTab === 'opsiyonlar' ? setOpsiyonlarModalOpen(true) : setSablonlarModalOpen(true))}
            className='bg-main p-2 h-fit rounded-md text-white font-semiBold text-[14px]'
          >
            + {selectedTab === 'opsiyonlar' ? 'Yeni Opsiyon Ekle' : 'Yeni Şablon Ekle'}
          </button>
        </div>
        <div className='mt-8 flex gap-x-4 font-medium text-black'>
          <button
            onClick={() => setSelectedTab('opsiyonlar')}
            className={`p-3 rounded-lg ${selectedTab === 'opsiyonlar' ? 'bg-main text-white' : 'bg-white'}`}
          >
            <p>Opsiyonlar</p>
          </button>
          <button
            onClick={() => setSelectedTab('sablonlar')}
            className={`p-3 rounded-lg ${selectedTab === 'sablonlar' ? 'bg-main text-white' : 'bg-white'}`}
          >
            <p>Şablonlar</p>
          </button>
        </div>
        {selectedTab === 'opsiyonlar' ? (
          <RenderOpsiyonlar
            setOptionalData={setOptionalData}
            optionalData={optionalData}
            setIsRefresh={setIsRefresh}
            isRefresh={isRefresh}
          />
        ) : (
          <RenderSablonlar
            templateData={templateData}
            setTemplateData={setTemplateData}
            isRefresh={isRefresh}
          />
        )}

        <Modal
          open={opsiyonlarModalOpen}
          onClose={() => setOpsiyonlarModalOpen(false)}
          className='focus:bg-white'
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              maxHeight: '90%',
              overflowY: 'auto',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              px: 2,
              py: 1,
            }}
          >
            <div>
              <div className='flex justify-between'>
                <h2 className=' text-[19px] font-bold'>Opsiyon Ekle</h2>
                <FaX
                  onClick={() => setOpsiyonlarModalOpen(false)}
                  className='cursor-pointer self-center'
                />
              </div>
              {inputFields.map((field) => (
                <div
                  key={field.id}
                  className='mt-4 pr-2 flex w-full justify-between'
                >
                  <div className='w-10/12'>
                    <FormControl
                      fullWidth
                      sx={{m: 1}}
                      className='bg-[#F9F9F9] '
                    >
                      <InputLabel
                        className='font-semiBold'
                        htmlFor='outlined-adornment-opsiyon-adi'
                      >
                        Opsiyon Adı
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-opsiyon-adi'
                        label='Opsiyon Adı'
                        className='font-medium text-[17px]'
                        type='string'
                        placeholder='Mısır'
                        value={field.value}
                        onChange={(e) => handleInputChange(field.id, e.target.value)}
                      />
                    </FormControl>
                  </div>
                  <button
                    onClick={handleAddInput}
                    className='w-1/12 self-center text-[21px] bg-main text-white p-1 rounded-full'
                  >
                    <FaPlus />
                  </button>
                </div>
              ))}

              <div className='mt-4'>
                <button
                  onClick={handleSubmit}
                  className={`bg-main py-2 px-4 rounded-md text-white font-bold text-[14px] w-full`}
                >
                  Kaydet
                </button>
                <button
                  onClick={() => setInputFields([{id: 1, value: ''}])}
                  className='mt-2 text-center w-full font-bold text-[14px] text-main'
                >
                  Temizle
                </button>
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={sablonlarModalOpen}
          onClose={() => setSablonlarModalOpen(false)}
          className='focus:bg-white'
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '40%',
              maxHeight: '90%',
              overflowY: 'auto',
              bgcolor: 'background.paper',
              px: 2,
              py: 2,
            }}
          >
            <div>
              <div className='flex justify-between'>
                <h2 className=' text-[19px] font-bold'>{step == 1 ? 'Şablon Oluştur' : `Şablona fiyat belirle`}</h2>
                <FaX
                  onClick={() => setSablonlarModalOpen(false)}
                  className='cursor-pointer self-center'
                />
              </div>

              {step == 1 ? (
                <div>
                  <div className='mt-4'>
                    <FormControl
                      fullWidth
                      sx={{m: 1, pr: 2}}
                      className='bg-[#F9F9F9]'
                    >
                      <InputLabel
                        className='font-semiBold'
                        htmlFor='outlined-adornment-sablon-adi  w-10/12'
                      >
                        Şablon Adı
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-sablon-adi'
                        label='Şablon Adı'
                        className='font-medium text-[17px]'
                        type='string'
                        placeholder='Büyük boy pizza'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  <div className='mt-4 flex flex-col gap-y-2'>
                    <FormGroup>
                      {optionalData?.map((item, index) => (
                        <div
                          className='flex items-center'
                          key={index}
                        >
                          <Checkbox
                            checked={checkChecked(item)}
                            sx={{'&.Mui-checked': {color: '#FF0100'}}}
                            onChange={(e) => {
                              const check = checkChecked(item);
                              if (check) {
                                const tempArray = [...templatesValue];
                                tempArray.map((element, index) => {
                                  if (element.optionalID == item._id) {
                                    tempArray.splice(index, 1);
                                  }
                                });

                                setTemplatesValues(tempArray);
                              } else {
                                const tempArray = [...templatesValue];
                                tempArray.push({
                                  name: item.name,
                                  price: 0,
                                  optionalID: item._id,
                                  active: item.active,
                                });
                                setTemplatesValues(tempArray);
                              }
                            }}
                          />
                          <p className=' font-medium'>{item.name}</p>
                        </div>
                      ))}
                    </FormGroup>
                  </div>
                </div>
              ) : (
                <div>
                  <div className='mt-4 flex flex-col gap-y-3'>
                    <FormGroup>
                      {templatesValue?.map((item, index) => (
                        <div
                          className='flex items-center'
                          key={index}
                        >
                          <p className=' font-medium'>-{item.name}</p>
                          <FormControl
                            size='small'
                            sx={{m: 1, ml: 3, width: 150}}
                            className='bg-[#F9F9F9]'
                          >
                            <InputLabel
                              className='font-semiBold'
                              htmlFor='outlined-adornment-opsiyon-adi  w-4/12'
                            >
                              Opsiyon Fiyatı
                            </InputLabel>
                            <OutlinedInput
                              startAdornment={<InputAdornment position='start'>₺</InputAdornment>}
                              id='outlined-adornment-opsiyon-fiyati'
                              label='Opsiyon Fiyatı'
                              className='font-medium text-[17px]'
                              type='number'
                              placeholder='Büyük boy pizza'
                              value={item.price}
                              onChange={(e) => {
                                const tempArray = [...templatesValue];
                                tempArray.map((element, index) => {
                                  if (item.optionalID == element.optionalID) {
                                    if (e.target.value == '') {
                                      element.price = '';
                                    } else {
                                      const newPrice = parseFloat(e.target.value);
                                      if (!isNaN(newPrice)) {
                                        element.price = e.target.value;
                                      }
                                    }
                                  }
                                });
                                setTemplatesValues(tempArray);
                              }}
                            />
                          </FormControl>
                        </div>
                      ))}
                    </FormGroup>
                  </div>
                </div>
              )}

              <div className='mt-4'>
                <button
                  onClick={() => {
                    if (step == 1) {
                      if (title.length > 0 && templatesValue.length > 0) {
                        setStep(2);
                      } else if (templatesValue.length == 0) {
                        toast.error('Lütfen en az 1 tane opsiyon seçiniz');
                      } else {
                        toast.error('Lütfen şablonunuza bir isim verin.');
                      }
                    } else {
                      let error = false;
                      templatesValue.map((item) => {
                        if (!item.price) {
                          error = true;
                        }
                      });

                      if (error) {
                        toast.error('Lütfen tüm fiyatları doldurunuz.');
                      } else {
                        axios
                          .post(
                            'https://api.yemek.club/restaurant/api/addTemplates',
                            {
                              title,
                              value: templatesValue,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${MainStore.token}`,
                              },
                            }
                          )
                          .then((res) => {
                            if (res.data.success) {
                              setTemplatesValues([]);
                              setStep(1);
                              setTitle('');
                              setSablonlarModalOpen(false);
                              toast.success(res.data.success);
                            } else {
                              toast.error(res.data.error);
                            }
                          });
                      }
                    }
                  }}
                  className={`bg-main py-2 px-4 rounded-md text-white font-bold text-[14px] w-full`}
                >
                  {step == 1 ? 'Fiyat Belirle' : 'Kaydet'}
                </button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </AuthLayout>
  );
}

export default Page;
