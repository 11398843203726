import React, { useEffect } from 'react'

function SetLocalStorage() {
    useEffect(()=>{
        const token = window.location.href.split('?')[1]
        localStorage.setItem('token',token)
        window.location.href = '/'
    },[])
  return (
    <div>Aktarılıyorsunuz...</div>
  )
}

export default SetLocalStorage