import CardHeadingText from '../../../components/Text/CardHeadingText';
import HeadingText from '../../../components/Text/HeadingText';
import {Checkbox, FormControl, FormControlLabel, FormGroup, Tooltip, createTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {FaInfo, FaLeaf, FaShare} from 'react-icons/fa';
import {FaArrowUpFromBracket} from 'react-icons/fa6';
import {WhatsappShareButton} from 'react-share';
import AuthLayout from '../../../layout/AuthLayout';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import MainStore from '../../../store/MainStore';
import moment from 'moment';
import toast from 'react-hot-toast';

function Page() {
  type OrderedFoodProps = {
    _id: string;
    name: string;
    price: number;
    discount?: string;
    rating?: number;
    restaurantName: string;
    province: string;
    district: string;
    amount: number;
    selectable: {
      required: [
        {
          name: string;
          value: [
            {
              name: string;
              price: number;
              optionalID?: string;
              active: boolean;
            }
          ];
          selected: number;
          active: boolean;
          templateID?: string;
        }
      ];
      optional: [
        {
          name: string;
          value: [
            {
              name: string;
              price: number;
              optionalID?: string;
              active: boolean;
            }
          ];
          selected: number;
          active: boolean;
          templateID?: string;
        }
      ];
      promotion: [
        {
          name: string;
          value: [
            {
              name: string;
              price: number;
              optionalID?: string;
              active: boolean;
            }
          ];
          selected: number;
          active: boolean;
          templateID?: string;
        }
      ];
    };
    multiSelect: [
      {
        name: string;
        selectType?: string;
        value: [
          {
            name: string;
            price: string;
            active: boolean;
            optionalID?: string;
          }
        ];
        selected: [];
        active: boolean;
        templateID?: string;
      }
    ];
    removeable: [
      {
        name: string;
        removed: boolean;
      }
    ];
    foodImage?: string;
  };

  type OrderDataProps = {
    _id: string;
    customerData: {
      customerName: string;
      customerId: string;
      customerMail: string;
      customerPhone: string;
      customerAddress: string;
    };
    restaurantData: {
      rId: string;
      rName: string;
      rUri?: string;
    };
    paymentType: string;
    totalPaid: number;
    takeAwayTime?: string;
    orderStatus: string;
    deliveryOptions: string;
    paymentId?: string;
    review?: boolean;
    customerNote: string;
    orderedFood: OrderedFoodProps[];
    created_at: Date;
  };

  const [orderStatus, setOrderStatus] = useState<string>('Hazırlanıyor');
  const [appReady, setAppReady] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderDataProps | null>(null);
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      axios
        .get(`https://api.yemek.club/restaurant/api/getSelectedOrder/${id}`, {
          headers: {Authorization: `Bearer ${MainStore.token}`},
        })
        .then((res) => {
          setOrderData(res.data);
          setOrderStatus(res.data.orderStatus);
          setAppReady(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);

  if (!appReady) {
    return null;
  }

  if (!orderData) {
    return null;
  }

  const whatsappLinkGenerate = () => {
    if (orderData) {
      let link = `https://web.whatsapp.com/send?text=Yemek.Club #${orderData._id} Nolu Sipariş Detayları%0A%0ASipariş Bilgileri;%0A`;
      orderData.orderedFood.forEach((food, index) => {
        const cleanedName = food.name.replace(/&/g, ''); // '&' karakterini kaldır

        link += `${cleanedName} (X${food.amount})%0A`;
      });
      link += `%0AMüşteri Bilgileri;%0AMüşteri Adı:${orderData.customerData.customerName}%0ATelefon Numarası:+${orderData.customerData.customerPhone}%0AMüşteri Adresi: ${orderData.customerData.customerAddress}`;
      return link;
    }
  };

  const changeOrderStatus = () => {
    axios
      .post(
        `https://api.yemek.club/restaurant/api/changeOrderStatus/${orderData._id}`,
        {orderStatus},
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        if (res.data.success) {
          toast.success('Sipariş durumu başarıyla değiştirildi !');
        } else {
          toast.error('Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.');
        }
      });
  };

  return (
    <AuthLayout>
      <div>
        <div className='mt-8 flex justify-between '>
          <div>
            <HeadingText
              title={`#${orderData._id} No'lu Sipariş Detayları`}
              subTitle={'Siparişini görüntüleyip düzenleyebilirsiniz!'}
            />
          </div>
          <div className='flex gap-y-2 items-center gap-x-2'>
            <a
              target='_blank'
              rel='noreferrer'
              href={whatsappLinkGenerate()}
            >
              <button className='p-2 bg-main rounded-md text-bold text-white font-bold flex items-center gap-x-1'>
                <FaArrowUpFromBracket />
                <p>Siparişi Paylaş</p>
              </button>
            </a>
            <Tooltip
              title={`Yemek.Club'ın çevrecilik anlayışıyla geliştirdiği yeni özellik "Siparişi Paylaş" sayesinde, siparişinizi terminalden çıkarmadan tüm detayları kuryenizle "Whatsapp" üzerinden saniyeler içerisinde paylaşabilirsiniz! `}
            >
              <div className='bg-[#d1d1d1] w-fit p-2 rounded-full h-fit'>
                <FaInfo className='text-gray-600' />
              </div>
            </Tooltip>
          </div>
        </div>

        <div className='flex flex-col lg:flex-row mt-8 gap-x-2 gap-y-8'>
          <div className=' bg-white w-full lg:w-9/12 rounded-md p-4'>
            <div className=''>
              <div>
                <CardHeadingText
                  title={`Sipariş Edilen Ürünler`}
                  subTitle={' '}
                />
              </div>

              <div className='flex flex-col gap-y-8 my-8'>
                {orderData.orderedFood.map((item, index) => (
                  <div key={index}>
                    <div className='flex gap-x-4 '>
                      <div>
                        {item.foodImage ? (
                          <img
                            src={item.foodImage}
                            className='w-[250px] rounded-md h-[175px]'
                          />
                        ) : (
                          <div className='w-[250px] h-[175px] rounded-md bg-[#F9F9F9]' />
                        )}
                      </div>
                      <div>
                        <div>
                          <h2 className='font-semiBold text-[19px]'>{item.name}</h2>
                        </div>

                        <div className='flex flex-col font-semiBold text-black/70 text-[15px]'>
                          <div>
                            <h6>
                              <b className='text-black'>Çıkartılacak Malzemeler</b> :{' '}
                              <span>
                                {item?.removeable?.map((element) => element.removed && <span>{element.name}, </span>)}
                              </span>
                            </h6>
                          </div>

                          <div>
                            <h6>
                              <b className='text-black'>Ekstra Malzeme</b> :{' '}
                              <span>
                                {item.multiSelect.map(
                                  (element, index) =>
                                    element.selected.length !== 0 &&
                                    element.selected.map(
                                      (elements, index) =>
                                        elements !== 0 && (
                                          <span>{`${element.value[elements].name} (${element.value[elements].price}), `}</span>
                                        )
                                    )
                                )}
                              </span>
                            </h6>
                          </div>

                          {item?.selectable?.required?.map((optional) => (
                            <div>
                              <h6>
                                <b className='text-black'>{optional.name}</b> :{' '}
                                {`${optional.value[optional.selected].name} (${
                                  optional.value[optional.selected].price
                                }₺)`}
                              </h6>
                            </div>
                          ))}

                          {item?.selectable?.optional?.map((optional) => (
                            <div>
                              <h6>
                                <b className='text-black'>{optional.name}</b> :{' '}
                                {`${optional.value[optional.selected].name} (${
                                  optional.value[optional.selected].price
                                }₺)`}
                              </h6>
                            </div>
                          ))}

                          {item?.selectable?.promotion?.map((optional) => (
                            <div>
                              <h6>
                                <b className='text-black'>{optional.name}</b> :{' '}
                                {`${optional.value[optional.selected].name} (${
                                  optional.value[optional.selected].price
                                }₺)`}
                              </h6>
                            </div>
                          ))}
                          <div>
                            <h6>
                              <b className='text-black'>Adet:</b> {item.amount}
                            </h6>
                          </div>
                          <div>
                            <h6>
                              <b className='text-black'>Fiyat:</b> {item.price}₺
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className=' w-full lg:w-3/12 flex flex-col gap-y-2 '>
            <div className='bg-white rounded-md p-4'>
              <div>
                <CardHeadingText
                  title={`Sipariş Detayları`}
                  subTitle={' '}
                />
              </div>
              <div className='flex flex-col gap-y-1 font-semiBold text-black/80 text-[15px]'>
                <div>
                  <p>
                    <b className='text-black'>Sipariş Tarihi:</b>{' '}
                    {`${moment(orderData.created_at).format('DD.MM.YYYY')} ${moment(orderData.created_at).format(
                      'HH:MM'
                    )}`}
                  </p>
                </div>
                <div>
                  <p>
                    <b className='text-black'>Teslimat Tipi:</b>{' '}
                    {orderData.deliveryOptions == 'takeAway' ? 'Gel Al' : 'Adrese Teslim'}
                  </p>
                </div>
                {orderData.deliveryOptions == 'takeAway' && (
                  <div>
                    <p>
                      <b className='text-black'>Gel Al Saati:</b> {orderData?.takeAwayTime}
                    </p>
                  </div>
                )}
                <div>
                  <p>
                    <b className='text-black'>Ödeme Tipi:</b>{' '}
                    {orderData.paymentType == 'onlinePayment' || 'takeAway'
                      ? 'Online Ödeme'
                      : (orderData.paymentType = 'creditCard' ? 'Kapıda Kredi Kartı' : 'Kapıda Nakit')}
                  </p>
                </div>
                <div>
                  <p>
                    <b className='text-black'>Sipariş Tutarı:</b> {orderData.totalPaid}₺
                  </p>
                </div>
                <div>
                  <p>
                    <b className='text-main'>Müşteri Notu:</b> {orderData.customerNote}
                  </p>
                </div>
              </div>
            </div>

            <div className='bg-white rounded-md p-4 '>
              <div>
                <CardHeadingText
                  title={`Sipariş Durumu`}
                  subTitle={' '}
                />
              </div>

              <div className='mt-2'>
                <FormGroup className='mt-2'>
                  <div className='flex items-center gap-y-1'>
                    <Checkbox
                      checked={orderStatus == 'Teslim Edildi' && true}
                      onChange={(e) => setOrderStatus('Teslim Edildi')}
                      sx={{
                        width: 27,
                        height: 27,
                        color: '#FF0100',
                        '&.Mui-checked': {color: '#FF0100'},
                      }}
                    />
                    <p className='font-medium text-[16px]'>Teslim Edildi</p>
                  </div>
                  <div className='flex items-center mt-1'>
                    <Checkbox
                      checked={orderStatus == 'Yolda' && true}
                      onChange={(e) => setOrderStatus('Yolda')}
                      sx={{
                        width: 27,
                        height: 27,
                        color: '#FF0100',
                        '&.Mui-checked': {color: '#FF0100'},
                      }}
                    />
                    <p className='font-medium text-[16px]'>Yolda</p>
                  </div>
                  <div className='flex items-center mt-1'>
                    <Checkbox
                      checked={orderStatus == 'Hazırlanıyor' && true}
                      onChange={(e) => setOrderStatus('Hazırlanıyor')}
                      sx={{
                        width: 27,
                        height: 27,
                        color: '#FF0100',
                        '&.Mui-checked': {color: '#FF0100'},
                      }}
                    />
                    <p className='font-medium text-[16px]'>Hazırlanıyor</p>
                  </div>
                  <div className='flex items-center mt-1'>
                    <Checkbox
                      checked={orderStatus == 'Beklemede' && true}
                      onChange={(e) => setOrderStatus('Beklemede')}
                      sx={{
                        width: 27,
                        height: 27,
                        color: '#FF0100',
                        '&.Mui-checked': {color: '#FF0100'},
                      }}
                    />
                    <p className='font-medium text-[16px]'>Beklemede</p>
                  </div>
                  <div className='flex items-center mt-1'>
                    <Checkbox
                      checked={orderStatus == 'İptal Edildi' && true}
                      onChange={(e) => setOrderStatus('İptal Edildi')}
                      sx={{
                        width: 27,
                        height: 27,
                        color: '#FF0100',
                        '&.Mui-checked': {color: '#FF0100'},
                      }}
                    />
                    <p className='font-medium text-[16px]'>İptal Edildi</p>
                  </div>
                </FormGroup>
              </div>

              <div>
                <button
                  onClick={changeOrderStatus}
                  className='p-2 mt-4 bg-main rounded-md text-bold text-white font-bold flex items-center gap-x-1'
                >
                  <p>Durum Güncelle</p>
                </button>
              </div>
            </div>

            <div className='bg-white rounded-md p-4 '>
              <div>
                <CardHeadingText
                  title={`Müşteri Detayları`}
                  subTitle={' '}
                />
              </div>
              <div className='flex flex-col gap-y-1 font-semiBold text-black/80 text-[15px]'>
                <div>
                  <p>
                    <b className='text-black'>Müşteri Adı:</b> {orderData.customerData.customerName}
                  </p>
                </div>
                <div>
                  <a href='tel:905423991919'>
                    <b className='text-black'>İletişim Bilgisi:</b> +{orderData.customerData.customerPhone}
                  </a>
                </div>
                <div>
                  <p>
                    <b className='text-black'>Ödeme Tipi:</b>{' '}
                    {orderData.paymentType == 'onlinePayment' || 'takeAway'
                      ? 'Online Ödeme'
                      : (orderData.paymentType = 'creditCard' ? 'Kapıda Kredi Kartı' : 'Kapıda Nakit')}
                  </p>
                </div>
                <div>
                  <p>
                    <b className='text-black'>Müşteri Adresi:</b> {orderData.customerData.customerAddress}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Page;
