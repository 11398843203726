import * as React from "react";
import type { SVGProps } from "react";
const FoodList = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        fill="none"
        {...props}
    >
        <path
            stroke="#28303F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M15 10v1.667a5 5 0 0 0 10 0V10"
        />
        <path
            stroke="#28303F"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M26.02 5H13.98a6.67 6.67 0 0 0-6.575 5.57L4.627 27.238C3.95 31.301 7.083 35 11.203 35h17.594c4.12 0 7.253-3.7 6.576-7.763l-2.778-16.666A6.67 6.67 0 0 0 26.02 5Z"
        />
    </svg>
);
export default FoodList;