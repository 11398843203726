import HeadingText from '../../../components/Text/HeadingText';
import {FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import AuthLayout from '../../../layout/AuthLayout';
import axios from 'axios';
import MainStore from '../../../store/MainStore';
import {observer} from 'mobx-react';
import toast from 'react-hot-toast';

type FoodListProps = {
  name: string;
  price: string;
  discount?: number | string;
  discountType?: string;
  foodImage?: string;
};

function Page() {
  const [foodList, setFoodList] = useState<FoodListProps[] | []>([]);
  const [appReady, setAppReady] = useState<boolean>(false);
  useEffect(() => {
    axios
      .get('https://api.yemek.club/restaurant/api/getMyBulkPriceList', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setFoodList(res.data);
        setAppReady(true);
      });
  }, []);

  const changeBulkPrice = async () => {
    axios
      .post('https://api.yemek.club/restaurant/api/changeBulkPrice', foodList, {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        if (res.data.success) {
          toast.success('Fiyatlarınız başarıyla güncellenmiştir.');
        } else {
          toast.error('Bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.');
        }
      });
  };

  if (!appReady) {
    return null;
  }
  return (
    <AuthLayout>
      <div>
        <div className='mt-8'>
          <HeadingText
            title='Toplu Fiyat Güncelle'
            subTitle={'Yemeklerinin fiyatlarını tek bir tuşla güncelleyebilirsiniz!'}
          />
        </div>

        <div className='bg-white p-8 rounded-[20px] w-full mt-8 flex flex-col gap-y-6'>
          <div className='mb-2 flex justify-between'>
            <div />
            <div>
              <p
                className='font-bold text-main text-[18px] cursor-pointer'
                onClick={() => {
                  const tempArray = [...foodList];
                  tempArray.map((item) => {
                    item.discount = 0;
                    item.discountType = 'none';
                  });
                  setFoodList(tempArray);
                }}
              >
                Tüm indirimleri kaldır
              </p>
            </div>
          </div>
          {foodList.map((item, index) => (
            <div
              key={index}
              className='flex border-b-[1px] border-b-black/10 pb-6'
            >
              <div className='flex flex-col md:flex-row  items-center'>
                <div className='flex flex-row items-center mb-1 md:w-4/12'>
                  <div className='mr-8 font-semibold text-[16px] text-[#3E4954]'>
                    <p>{index + 1}</p>
                  </div>
                  <div className='flex gap-x-2 items-center w-[300px] md:w-[250px] lg:w-[300px] '>
                    <img
                      src={item.foodImage}
                      className='w-[40px] rounded-md'
                    />
                    <p className='font-bold text-[16px] text-[#3E4954]'>{item.name}</p>
                  </div>
                </div>

                <div className='flex justify-between items-center md:w-8/12'>
                  <div className='mr-8 w-4/12'>
                    <FormControl
                      fullWidth
                      sx={{m: 1}}
                      className='bg-[#F9F9F9]'
                    >
                      <InputLabel
                        className='font-semiBold'
                        htmlFor='outlined-adornment-amount'
                      >
                        Fiyat
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-amount'
                        startAdornment={<InputAdornment position='start'>₺</InputAdornment>}
                        label='Fiyat'
                        className='font-medium text-[17px]'
                        value={item.price}
                        type='number'
                        defaultValue={parseFloat(item.price).toFixed(2)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          console.log(parseFloat(e.target.value));
                          const tempArray = [...foodList];
                          tempArray[index].price = parseFloat(e.target.value).toString();
                          setFoodList(tempArray);
                        }}
                      />
                    </FormControl>
                  </div>

                  <div className='w-4/12 md:w-[160px] '>
                    <TextField
                      id='outlined-select-currency'
                      select
                      label='İndirim Tipi'
                      className='font-medium text-[17px] bg-[#F9F9F9] max-w-[100px] md:min-w-[150px]'
                      defaultValue='fixed'
                      value={item.discountType}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const tempArray = [...foodList];
                        tempArray[index].discountType = e.target.value;
                        setFoodList(tempArray);
                      }}
                    >
                      <MenuItem value={'none'}>İndirim Yok</MenuItem>
                      <MenuItem value={'fixed'}>Sabit Fiyat</MenuItem>
                      <MenuItem value={'percent'}>Yüzde</MenuItem>
                    </TextField>
                  </div>

                  <div className='w-4/12'>
                    <FormControl
                      fullWidth
                      sx={{m: 1}}
                      className='bg-[#F9F9F9]'
                    >
                      <InputLabel
                        className='font-semiBold'
                        htmlFor='outlined-adornment-amount'
                      >
                        İndirim Tutarı
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-amount'
                        startAdornment={
                          <InputAdornment position='start'>
                            {item.discountType == 'fixed' ? '₺' : item.discountType == 'percent' ? '%' : ''}
                          </InputAdornment>
                        }
                        label='İndirim Tutarı'
                        className='font-medium text-[17px]'
                        type='number'
                        value={item.discount}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          console.log(parseFloat(e.target.value));
                          const tempArray = [...foodList];
                          tempArray[index].discount = parseFloat(e.target.value);
                          setFoodList(tempArray);
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div>
            <button
              onClick={changeBulkPrice}
              className='p-3 bg-main rounded-md text-bold text-white'
            >
              Fiyatları Güncelle
            </button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default observer(Page);
