import {observable, action, makeAutoObservable} from 'mobx';

type UserProps = {
  deliveryOptions: {
    takeAway: boolean;
    onlinePayment: boolean;
    cashPayment: boolean;
    creditCard: boolean;
  };
  workingHours: {
    sunday: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
  };
  twoFA: boolean;
  autoAccept: boolean;
  _id: string;
  restaurantName: string;
  restaurantLogo: string;
  rating: boolean;
  ownerName: string;
  email: string;
  password: string;
  ownerPhoneNumber: boolean;
  province: string;
  district: string;
  visible: boolean;
  category: string;
  subCategory: [];
  fullAddress: string;
  neighbourhood: {
    name: string;
    deliveryTime: string;
    minPrice: number;
    district: string;
    _id: string;
    comments: [];
    created_at: string;
    restaurantCoverPhoto: string;
    subMerchantKey: string;
  };
};

class MainStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable auth = false as boolean;
  @observable token = '' as string;
  @observable user = {} as UserProps;
  @observable firstLogin = true as boolean;

  @action login(auth: boolean, token: string, user: UserProps) {
    localStorage.setItem('token', token);
    this.auth = auth;
    this.token = token;
    this.user = user;
  }

  @action logout() {
    window.location.href = '/';
    localStorage.clear();
    this.auth = false;
    this.token = '';
  }

  @action changeFirstLogin() {
    this.firstLogin = false;
  }
}

export default new MainStore();
