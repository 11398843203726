import AllOrder from '../components/Icon/Stats/AllOrder'
import WaitingOrder from '../components/Icon/Stats/WaitingOrder'
import RevenueIcon from '../components/Icon/Stats/RevenueIcon'
import FoodList from '../components/Icon/Stats/FoodList'

export const StatsCardList = [
    {
        Icon: <AllOrder />,
        stats: 250,
        title: 'Tüm Siparişler',
        href: '/siparisler?bekleyenler',
        subInfo: 24.35
    },
    {
        Icon: <WaitingOrder />,
        stats: 50,
        title: 'Bekleyen Sipariş',
        href: '/siparisler?bekleyenler',
        subInfo: -11.09
    },
    {
        Icon: <RevenueIcon />,
        stats: 7980,
        title: 'Kazanç',
        href: '#',
        subInfo: 38.44
    },
    {
        Icon: <FoodList />,
        stats: 38,
        title: 'Ürün Sayısı',
        href: '/yemekler'
    },
]