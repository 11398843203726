import * as React from "react";
import type { SVGProps } from "react";
const UpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={15}
    fill="none"
    {...props}
  >
    <path
      stroke="#2BC155"
      strokeWidth={2}
      d="M1 13.5c.918-1.084 3.897-4.272 5.5-6l6 3 7-9"
    />
    <path
      fill="url(#a)"
      d="M6.5 7.5c-1.603 1.728-4.582 4.916-5.5 6h18.5v-12l-7 9z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={10.25}
        x2={11}
        y1={3}
        y2={13.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2BC155" stopOpacity={0.73} />
        <stop offset={1} stopColor="#2BC155" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default UpIcon;