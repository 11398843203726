import * as React from "react";
import type { SVGProps } from "react";

interface IconProps extends SVGProps<SVGSVGElement> {}

const WaitingOrder = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={41}
        fill="none"
        {...props}
    >
        <circle cx={20} cy={6} r={5} stroke="#28303F" strokeWidth={1.5} />
        <path
            stroke="#28303F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M21.25 6.875H20V5"
        />
        <path
            stroke="#28303F"
            strokeWidth={1.5}
            d="M6.667 17.667v12.097a2 2 0 0 0 1.105 1.789l11.334 5.666a2 2 0 0 0 1.788 0l11.334-5.666a2 2 0 0 0 1.105-1.789V17.667"
        />
        <path
            stroke="#28303F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M30 9.333 33.333 11 20 17.667 6.667 11 10 9.333"
        />
        <path
            stroke="#28303F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M33.333 11 20 17.667l3.333 5L36.667 16zM6.667 11 20 17.667l-3.333 5L3.333 16z"
        />
    </svg>
);
export default WaitingOrder;