import React from 'react'

interface TextProps {
    title: String,
    subTitle: String
}

function CardHeadingText({ title, subTitle }: TextProps) {
    return (
        <div>
            <h2 className='font-bold text-xl'>{title}</h2>
            <p className='text-black/40 font-semibold mt-1'>{subTitle}</p>
        </div>
    )
}

export default CardHeadingText