import { BsArrowLeftShort } from "react-icons/bs";
import {
  MdOutlineDashboard,
  MdMenuBook,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { IoFastFood, IoMapSharp } from "react-icons/io5";
import { FaBox, FaComment, FaGift, FaShieldAlt } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { GiMeal } from "react-icons/gi";
export const menuList = [
  {
    name: "Kontrol Merkezi",
    Icon: MdOutlineDashboard,
    pathname: "/",
    spacing: true,
  },
  {
    name: "Tüm Siparişler",
    Icon: FaBox,
    pathname: "/siparisler",
    spacing: true,
    badge: false,
    badgeContent: 35,
  },
  /*
  {
    name: "Yorumlar",
    Icon: FaComment,
    pathname: "/yorumlar",
  },
  */
  {
    name: "Yemek Listem",
    Icon: MdMenuBook,
    pathname: "/yemekler",
    spacing: true,
  },
  {
    name: "Yemek Ekle",
    Icon: IoFastFood,
    pathname: "/yemek-ekle",
  },
  {
    name: "Opsiyonlar",
    Icon: GiMeal,
    pathname: "/opsiyonlar",
  },

  {
    name: "Bölgeler",
    Icon: IoMapSharp,
    pathname: "/bolgeler",
    spacing: true,
  },

  {
    name: "Restoran Ayarları",
    Icon: FaGear,
    pathname: "/ayarlar",
  },
  {
    name: "Güvenlik Ayarları",
    Icon: FaShieldAlt,
    pathname: "/guvenlik-ayarlari",
  },
  {
    name: "Hizmetlerimiz",
    Icon: FaGift,
    pathname: "/hizmetlerimiz",
    spacing: true,
  },
  {
    name: "Destek",
    Icon: MdOutlineSupportAgent,
    pathname: "/destek",
  },
];
