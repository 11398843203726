import EditIcon from '../../components/Icon/Food/EditIcon';
import RemoveIcon from '../../components/Icon/Food/RemoveIcon';
import HeadingText from '../../components/Text/HeadingText';
import {Rating, Tooltip} from '@mui/material';
import {DataGrid, GridColDef, GridRowsProp} from '@mui/x-data-grid';
import React from 'react';
import {trTR} from '@mui/x-data-grid/locales';
import AuthLayout from '../../layout/AuthLayout';

function Page() {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Sıra',
      maxWidth: 80,
      flex: 0.1,
      renderCell: (params) => <div className={`text-[#3E4954] font-semiBold text-[16px]`}>{params.row.id}</div>,
    },
    {
      field: 'name',
      headerName: 'Yemek Adı',
      minWidth: 210,
      flex: 0.2,
      renderCell: (params) => (
        <div className={` text-[#3E4954] font-semiBold flex gap-x-2 items-center`}>
          <img
            src={params.row.foodImage}
            className='w-[35px] rounded-md'
          />
          <p>{params.row.name}</p>
        </div>
      ),
    },
    {
      field: 'comment',
      headerName: 'Yorumlar',
      minWidth: 110,
      flex: 0.2,
      renderCell: (params) => <div className={` text-[#3E4954] font-semiBold`}>{params.row.comment}</div>,
    },
    {
      field: 'rating',
      headerName: 'Puan',
      minWidth: 210,
      flex: 0.2,
      renderCell: (params) => (
        <div>
          <Rating
            value={params.row.rating}
            readOnly
          />
        </div>
      ),
    },
    {
      field: 'visible',
      headerName: 'Durum',
      minWidth: 150,
      flex: 0.2,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Yanıtlandı', 'Cevap Verilmedi'],
      renderCell: (params) => (
        <div
          className={`${
            params.row.visible
              ? 'text-[#2BC155] bg-[#2BC155]/5 py-[10px] px-[16px] rounded-md'
              : 'text-main bg-main/5 py-[10px] px-[16px] rounded-md'
          } font-bold`}
        >
          {params.row.visible ? 'Yanıtlandı' : 'Yorum Yapılmadı'}
        </div>
      ),
    },
    {
      field: 'col6',
      headerName: 'İşlem',
      width: 10,
      flex: 0.05,
      align: 'center',
      renderCell: (params) => (
        <div className='flex gap-x-2'>
          <Tooltip
            title='Yemeği Düzenle'
            className='cursor-pointer'
          >
            <EditIcon />
          </Tooltip>
        </div>
      ),
      renderHeader: (params) => <></>,
      filterable: false,
      sortable: false,
      hideSortIcons: false,
      disableColumnMenu: true,
    },
  ];

  const rows: GridRowsProp = [
    {
      id: 1,
      name: 'Caffe Americano',
      foodImage: 'https://api.yemek.club/img/65c20a5bd0322ed6288ca2a5/2024-02-06T17-01-24.223Zfile.webp',
      comment: 'Kahvem biraz geç geldi ama tadı güzel',
      rating: 3.5,
      visible: true,
      col6: '',
    },
    {
      id: 2,
      name: 'Iced Caffe Latte',
      foodImage: 'https://api.yemek.club/img/65c20a5bd0322ed6288ca2a5/2024-02-06T17-11-38.295Zfile.webp',
      comment: 'Hızlı servisiniz için teşekkürler.',
      rating: 5,
      totalSale: 943,
      visible: false,
      col6: '',
    },
    {
      id: 3,
      name: 'White Chocolate Mocha',
      foodImage: 'https://api.yemek.club/img/65c20a5bd0322ed6288ca2a5/2024-02-06T17-29-29.652Zfile.webp',
      comment: 'Kahvemin bir kısmı teslimat sırasında dökülmüştü',
      rating: 2,
      visible: true,
      col6: '',
    },
    {
      id: 4,
      name: 'Brownie',
      foodImage: 'https://api.yemek.club/img/65c20a5bd0322ed6288ca2a5/2024-02-06T17-36-29.884Zfile.webp',
      comment: 'Fena değil',
      rating: 3,
      visible: false,
      col6: '',
    },
    {
      id: 5,
      name: 'Kremalı Havuçlu Kek',
      foodImage: 'https://api.yemek.club/img/65c20a5bd0322ed6288ca2a5/2024-02-06T18-01-56.251Zfile.webp',
      comment: 'Çok beğendim yeni favroi tatlım',
      rating: 5,
      visible: true,
      col6: '',
    },
  ];

  return (
    <AuthLayout>
      <div>
        <div className='mt-8 flex items-center justify-between'>
          <HeadingText
            title='Yorumlar'
            subTitle={'Siparişe ait yemeklerinize gelen yorumları inceleyebilirsiniz !'}
          />
        </div>

        <div className='mt-8 w-full  bg-white p-8 rounded-[20px]'>
          <div style={{width: '100%'}}>
            <DataGrid
              rows={rows}
              columns={columns}
              sx={{
                border: 0,
                borderColor: '#D3D6E4',
                '.MuiDataGrid-columnHeader': {
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: 18,
                },
                '.MuiDataGrid-cell': {border: 0},
                '.MuiDataGrid-footerCell': {paddingBottom: 10},
                '.MuiDataGrid-footerContainer': {border: 0},
              }}
              localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Page;
