import HeadingText from '../../../components/Text/HeadingText';
import React, {useEffect, useState} from 'react';
import cityData from '../../../config/cityData.json';
import {
  Switch,
  Tooltip,
  ThemeProvider,
  createTheme,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import AuthLayout from '../../../layout/AuthLayout';
import axios from 'axios';
import MainStore from '../../../store/MainStore';
import {FaChevronRight} from 'react-icons/fa6';
import toast from 'react-hot-toast';
type Neighbourhood = {
  name: string;
  district: string;
  deliveryTime: string;
  minPrice: number | string;
  active: boolean;
};

type District = {
  name: string;
  neighbourhood: Neighbourhood[];
};

type CityDataProps = {
  district: string;
  neighbourhood: Neighbourhood[];
};

function Page() {
  const [city, setCity] = useState('Antalya');
  const [deliveryZone, setDeliveryZone] = useState<CityDataProps[] | null>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<CityDataProps | null>(null);
  const [index, setIndex] = useState<number>(-1);
  const [minPrice, setMinPrice] = useState<number | string>(0);
  const [deliveryTime, setDeliveryTime] = useState<string>('');

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FF0100',
      },
    },
  });

  useEffect(() => {
    axios
      .get('https://api.yemek.club/restaurant/api/getDeliveryZone', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setDeliveryZone(res.data);
      });
  }, []);

  const checkChecked = () => {
    if (deliveryZone) {
      const inactiveNeighbourhoods = deliveryZone[index].neighbourhood.filter((item) => !item.active);
      return inactiveNeighbourhoods.length === 0;
    } else {
      return false;
    }
  };

  const saveDeliveryZone = async () => {
    if (deliveryZone) {
      axios
        .post('https://api.yemek.club/restaurant/api/saveDeliveryZone', deliveryZone, {
          headers: {Authorization: `Bearer ${MainStore.token}`},
        })
        .then((res) => {
          if (res.data.success) {
            toast.success('Bölgeleriniz başarıyla kaydedildi.');
          }
        });
    }
  };

  const checkActiveAmount = (data: Neighbourhood[]) => {
    if (data) {
      let activeAmount = 0;
      data.forEach((item) => {
        if (item.active) {
          activeAmount++;
        }
      });
      console.log(activeAmount);
      return activeAmount;
    }
    return 0;
  };
  return (
    <AuthLayout>
      <div>
        <div className='mt-8'>
          <div className='flex justify-between'>
            <HeadingText
              title='Yeni Bölge Ekle'
              subTitle={' '}
            />
            <button
              onClick={saveDeliveryZone}
              className='p-1 px-7 bg-main font-bold text-white text-[16px] rounded-lg'
            >
              Kaydet
            </button>
          </div>
        </div>

        <div className='bg-white rounded-lg  mt-8'>
          <div className='flex'>
            <div className='md:w-3/12 lg:w-2/12 2xl:w-1/12 border-r-[1px] border-black/10'>
              {deliveryZone?.map((item, index1) => {
                const activeAmount = checkActiveAmount(item.neighbourhood);

                return (
                  <div
                    key={index1}
                    onClick={() => {
                      setSelectedDistrict(item);
                      setIndex(index1);
                      setDeliveryTime('');
                      setMinPrice(0);
                    }}
                    className={`border-b-[1px] border-black/10 p-3 py-4 font-semiBold text-[15px] cursor-pointer flex  justify-between items-center`}
                  >
                    <div>
                      {item.district}{' '}
                      <span className='font-medium text-black/60 text-[12px]'>{`(${activeAmount}/${item.neighbourhood.length})`}</span>
                    </div>

                    <div className='text-black/70 text-[13px]'>
                      <FaChevronRight />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className='w-full'>
              {selectedDistrict && (
                <div>
                  <div className='flex justify-between'>
                    <h3 className='text-[21px] font-bold pl-3 pt-1 w-8/12'>
                      {selectedDistrict.district} <span>({`24/${selectedDistrict.neighbourhood.length}`})</span>
                    </h3>
                    <div className='flex items-center '>
                      <div className='flex gap-x-2'>
                        <div>
                          <FormControl
                            sx={{m: 1, width: 200}}
                            className='bg-[#F9F9F9]'
                            size='small'
                            fullWidth
                          >
                            <InputLabel id='tahmini-teslim-suresi'>Tahmini Teslim Süresi</InputLabel>
                            <Select
                              labelId='tahmini-teslim-suresi'
                              id='tahmini-teslim-suresi'
                              label='Tahmini Teslim Süresi'
                              onChange={(e) => {
                                if (deliveryZone) {
                                  const tempArray = [...deliveryZone];
                                  tempArray[index].neighbourhood.map((item) => {
                                    item.deliveryTime = e.target.value as string;
                                  });
                                  setDeliveryZone(tempArray);
                                }
                              }}
                            >
                              <MenuItem value={'10-20'}>10 - 20 Dk.</MenuItem>
                              <MenuItem value={'20-30'}>20 - 30 Dk.</MenuItem>
                              <MenuItem value={'30-40'}>30 - 40 Dk.</MenuItem>
                              <MenuItem value={'40-50'}>40 - 50 Dk.</MenuItem>
                              <MenuItem value={'50-60'}>50 - 60 Dk.</MenuItem>
                              <MenuItem value={'60-70'}>60 - 70 Dk.</MenuItem>
                            </Select>
                          </FormControl>
                        </div>

                        <div>
                          <FormControl
                            sx={{m: 1, width: 200}}
                            className='bg-[#F9F9F9]'
                            size='small'
                            fullWidth
                          >
                            <InputLabel
                              className='font-semiBold'
                              htmlFor='min-sepet-tutari'
                            >
                              Min. Sepet Tutarı
                            </InputLabel>
                            <OutlinedInput
                              id='min-sepet-tutari'
                              startAdornment={<InputAdornment position='start'>₺</InputAdornment>}
                              label='Min. Sepet Tutarı'
                              className='font-medium text-[17px]'
                              type='number'
                              value={minPrice}
                              onChange={(e) => {
                                if (deliveryZone) {
                                  setMinPrice(e.target.value);
                                  const tempArray = [...deliveryZone];
                                  tempArray[index].neighbourhood.map((item) => {
                                    item.minPrice = e.target.value;
                                  });
                                  setDeliveryZone(tempArray);
                                }
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <ThemeProvider theme={theme}>
                        <Switch
                          color='primary'
                          size='small'
                          onChange={(e) => {
                            if (deliveryZone) {
                              const tempArray = [...deliveryZone];
                              tempArray[index].neighbourhood.map((item) => {
                                item.active = e.target.checked;
                              });
                              setDeliveryZone(tempArray);
                            }
                          }}
                          checked={checkChecked()}
                        />
                      </ThemeProvider>
                    </div>
                  </div>

                  <div className='mt-4'>
                    {deliveryZone && (
                      <div className='flex flex-col gap-y-1 p-2 w-full'>
                        {deliveryZone[index].neighbourhood.map((neighbourhood, index2) => (
                          <div
                            key={index2}
                            className='font-medium text-[14px] flex items-center justify-between'
                          >
                            <div className='flex items-center w-8/12'>
                              <div className='mr-2'>
                                <Tooltip title={neighbourhood.active ? 'Aktif' : 'Pasif'}>
                                  <div
                                    className={`${
                                      neighbourhood.active ? 'bg-green-600' : 'bg-main'
                                    } rounded-full w-2 h-2`}
                                  />
                                </Tooltip>
                              </div>
                              <p>{neighbourhood.name}</p>
                            </div>

                            <div className='flex items-center '>
                              <div className='flex gap-x-2'>
                                <FormControl
                                  sx={{m: 1, minWidth: 160}}
                                  className='bg-[#F9F9F9]'
                                  size='small'
                                  fullWidth
                                >
                                  <InputLabel id='demo-simple-select-label'>Tahmini Teslim Süresi</InputLabel>
                                  <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    label='Tahmini Teslim Süresi'
                                    value={deliveryZone[index].neighbourhood[index2].deliveryTime}
                                    onChange={(e) => {
                                      const tempArray = [...deliveryZone];
                                      tempArray[index].neighbourhood[index2].deliveryTime = e.target.value as string;
                                      setDeliveryZone(tempArray);
                                    }}
                                  >
                                    <MenuItem value={'10-20'}>10 - 20 Dk.</MenuItem>
                                    <MenuItem value={'20-30'}>20 - 30 Dk.</MenuItem>
                                    <MenuItem value={'30-40'}>30 - 40 Dk.</MenuItem>
                                    <MenuItem value={'40-50'}>40 - 50 Dk.</MenuItem>
                                    <MenuItem value={'50-60'}>50 - 60 Dk.</MenuItem>
                                    <MenuItem value={'60-70'}>60 - 70 Dk.</MenuItem>
                                  </Select>
                                </FormControl>
                                <FormControl
                                  sx={{m: 1}}
                                  className='bg-[#F9F9F9]'
                                  size='small'
                                  fullWidth
                                >
                                  <InputLabel
                                    className='font-semiBold'
                                    htmlFor='min-sepet-tutari'
                                  >
                                    Min. Sepet
                                  </InputLabel>
                                  <OutlinedInput
                                    id='min-sepet-tutari'
                                    startAdornment={<InputAdornment position='start'>₺</InputAdornment>}
                                    label='Min. Sepet'
                                    className='font-medium text-[17px]'
                                    type='number'
                                    value={neighbourhood.minPrice}
                                    onChange={(e) => {
                                      const tempArray = [...deliveryZone];
                                      tempArray[index].neighbourhood[index2].minPrice = e.target.value;
                                      setDeliveryZone(tempArray);
                                    }}
                                  />
                                </FormControl>
                              </div>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  color='primary'
                                  size='small'
                                  checked={neighbourhood.active ? true : false}
                                  onChange={(e) => {
                                    const tempArray = [...deliveryZone];
                                    tempArray[index].neighbourhood[index2].active = e.target.checked;
                                    setDeliveryZone(tempArray);
                                  }}
                                />
                              </ThemeProvider>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Page;
