import React from "react";
import AuthLayout from "../../../layout/AuthLayout";

function AuthPage404() {
  return (
    <AuthLayout>
      <div className="flex flex-col justify-center items-center h-[60%]">
        <img src="/assets/image/logo/logo.webp" className="w-[150px]" />
        <h3 className="text-[32px] font-semiBold">404 Not Found</h3>
        <p className="text-[18px] font-medium text-black/80 my-4">
          Aradığınız sayfayı bulamadık... Ana sayfaya dönmek için "Geri Dön"
          butonunu kullanabilirsiniz
        </p>
        <button
          onClick={() => (window.location.href = "/")}
          className="bg-main text-white font-semiBold text-[17px] p-3 rounded-lg"
        >
          Geri Dön
        </button>
      </div>
    </AuthLayout>
  );
}

export default AuthPage404;
