import * as React from "react";
import type { SVGProps } from "react";
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#28303F"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M22 12v6a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h6m3.686 2.023s0 1.43 1.43 2.86c1.431 1.43 2.861 1.43 2.861 1.43M9.155 15.99l3.003-.43a2.02 2.02 0 0 0 1.145-.572l8.105-8.105c.79-.79.79-2.07 0-2.86l-1.43-1.43c-.79-.79-2.071-.79-2.861 0l-8.105 8.105c-.31.309-.51.71-.572 1.144l-.43 3.003a1.01 1.01 0 0 0 1.145 1.145Z"
    />
  </svg>
);
export default EditIcon;