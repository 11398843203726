import React from "react";
import WaitingOrder from "../Icon/Stats/WaitingOrder";
import type { SVGProps } from "react";
import { SvgIconProps } from "@mui/material";
import UpIcon from "../Icon/Stats/UpIcon";
import DownIcon from "../Icon/Stats/DownIcon";

interface StatsProps {
  Icon: React.ReactElement<SvgIconProps>;
  stats: number;
  title: String;
  href: string;
  subInfo?: number;
}

function StatsCard({ stats, title, href, Icon, subInfo }: StatsProps) {
  return (
    <a
      href={href}
      className="bg-white relative rounded-[20px] flex md:w-5/12 lg:w-3/12 sm:w-11/12 px-6 py-8 mr-4 items-center cursor-pointer"
    >
      <div className="mr-6">{Icon}</div>
      <div>
        <div className="md:text-2xl 2xl:text-3xl font-bold">
          <h2>
            <span>{title == "Kazanç" ? "₺" : ""}</span>
            {`${
              title == "Kazanç" ? stats.toFixed(2).toString() : stats.toString()
            }`}
          </h2>
        </div>
        <div className="text-sm font-semibold text-black/70">
          <h3>{title}</h3>
        </div>
        {subInfo && (
          <div className="md:hidden lg:flex absolute right-6 bottom-2 flex items-center">
            <div className="mr-1">
              {subInfo < 0 ? <DownIcon /> : <UpIcon />}
            </div>
            <div className="text-black/40 font-semibold text-xs">
              {`${subInfo}%`} (24 Saat)
            </div>
          </div>
        )}
      </div>
    </a>
  );
}

export default StatsCard;
