import React from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import {Toaster} from 'react-hot-toast';
import Header from '../components/Header/Header';
import NotificationRequestModal from '../components/Modal/NotificationRequestModal';

function AuthLayout({children}: {children: React.ReactNode}) {
  return (
    <div className='flex'>
      <div className='hidden md:flex lg:flex lg:flex-col md:flex-col '>
        <Sidebar />
      </div>
      <div className='px-7 pt-2 flex-1'>
        <Header />
        {children}
        <Toaster position='bottom-right' />
      </div>
    </div>
  );
}

export default AuthLayout;
