import HeadingText from '../../components/Text/HeadingText';
import React, {useEffect, useState} from 'react';
import {DataGrid, GridRowsProp, GridColDef, GridRowModel} from '@mui/x-data-grid';
import {trTR} from '@mui/x-data-grid/locales';
import ActionIcon from '../../components/Icon/Order/ActionIcon';
import {Rating, Tooltip} from '@mui/material';
import ConfirmIcon from '../../components/Icon/Order/ConfirmIcon';
import RejectIcon from '../../components/Icon/Order/RejectIcon';
import ShowIcon from '../../components/Icon/Order/ShowIcon';
import EditIcon from '../../components/Icon/Food/EditIcon';
import RemoveIcon from '../../components/Icon/Food/RemoveIcon';
import AuthLayout from '../../layout/AuthLayout';
import axios from 'axios';
import MainStore from '../../store/MainStore';
import toast from 'react-hot-toast';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

type SelectedFoodType = {
  name: string;
  _id: string;
};

function Page() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<GridRowsProp | null>(null);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [selectedFood, setSelectedFood] = useState<SelectedFoodType | null>(null);
  const [isRender, setIsRender] = useState<boolean>(false);
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Sıra',
      maxWidth: 80,
      flex: 0.1,
      renderCell: (params) => (
        <div className={`text-[#3E4954] font-semiBold text-[16px]`}>
          {params.api.getAllRowIds().indexOf(params.id) + 1}
        </div>
      ),
    },
    {
      field: 'name',
      headerName: 'Yemek Adı',
      minWidth: 210,
      flex: 0.2,
      renderCell: (params) => (
        <div className={` text-[#3E4954] font-semiBold flex gap-x-2 items-center`}>
          <img
            src={params.row.foodImage}
            className='w-[35px] rounded-md'
          />
          <p>{params.row.name}</p>
        </div>
      ),
    },
    {
      field: 'category',
      headerName: 'Kategori',
      minWidth: 110,
      flex: 0.2,
      renderCell: (params) => <div className={` text-[#3E4954] font-semiBold`}>{params.row.category}</div>,
    },
    {
      field: 'rating',
      headerName: 'Puan',
      minWidth: 210,
      flex: 0.2,
      renderCell: (params) => (
        <div>
          <Rating
            value={params.row.rating}
            readOnly
          />
        </div>
      ),
    },
    {
      field: 'totalSale',
      headerName: 'Toplam Satış',
      minWidth: 110,
      flex: 0.2,
      renderCell: (params) => <div className={` text-[#3E4954] font-semiBold`}>{params.row.totalSale}</div>,
    },
    {
      field: 'price',
      headerName: 'Tutar',
      minWidth: 110,
      flex: 0.2,
      renderCell: (params) => <div className={` text-[#3E4954] font-semiBold`}>₺{params.row.price}</div>,
    },
    {
      field: 'visible',
      headerName: 'Durum',
      minWidth: 150,
      flex: 0.2,
      editable: true,
      type: 'singleSelect',
      valueOptions: ['Satışta', 'Satışa Kapalı'],
      renderCell: (params) => (
        <div
          className={`w-fit ${
            params.row.visible
              ? 'text-[#2BC155] bg-[#2BC155]/5 px-[16px] rounded-md'
              : 'text-main bg-main/5  px-[16px] rounded-md'
          } font-bold`}
        >
          {params.row.visible ? 'Satışta' : 'Satışa Kapalı'}
        </div>
      ),
    },

    {
      field: 'col6',
      headerName: 'İşlem',
      width: 10,
      flex: 0.05,
      align: 'center',
      renderCell: (params) => (
        <div className='flex gap-x-2 mt-3'>
          <Tooltip
            onClick={() => (window.location.href = `/yemekler/yemek-duzenle/${params.row._id}`)}
            title='Yemeği Düzenle'
            className='cursor-pointer'
          >
            <EditIcon />
          </Tooltip>

          <Tooltip
            onClick={() => {
              setSelectedFood(params.row);
              setAlertOpen(true);
            }}
            title='Yemeği Kaldır'
            className='cursor-pointer'
          >
            <RemoveIcon />
          </Tooltip>
        </div>
      ),
      renderHeader: (params) => <></>,
      filterable: false,
      sortable: false,
      hideSortIcons: false,
      disableColumnMenu: true,
    },
  ];

  useEffect(() => {
    axios
      .get('https://api.yemek.club/restaurant/api/getMyFoodList', {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        setRows(res.data);
        setIsLoading(false);
      });
  }, [isRender]);

  const handleRowEdit = async (updatedRow: GridRowModel, originalRow: GridRowModel) => {
    try {
      const visible = updatedRow.visible == 'Satışta' ? true : false;
      const newRow = {...updatedRow};
      newRow.visible = visible;
      const res = await axios.post(
        `https://api.yemek.club/restaurant/api/editFood/${updatedRow._id}`,
        {visible},
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      );

      if (res.data.success) {
        console.log('success');
        return newRow;
      } else {
        toast.error('Bir sorun oluştu lütfen daha sonra tekrar deneyiniz.');
        return originalRow;
      }
    } catch (error) {
      console.error('Error updating row:', error);
    }
  };

  const handleRowDelete = async () => {
    axios
      .get(`https://api.yemek.club/restaurant/api/deleteFood/${selectedFood?._id}`, {
        headers: {Authorization: `Bearer ${MainStore.token}`},
      })
      .then((res) => {
        if (res.data.success) {
          setIsRender(!isRender);
          toast.success('Ürününüz başarıyla kaldırıldı');
          setAlertOpen(false);
        } else {
          toast.error('Ürününüzü kaldırırken bir sorun ile karşılaştık. Lütfen daha sonra tekrar deneyiniz.');
          setAlertOpen(false);
        }
      });
  };

  return (
    <AuthLayout>
      <div>
        <div className='mt-8 flex items-center justify-between'>
          <HeadingText
            title='Yemek Listem'
            subTitle={'Menünüzdeki yemekleri görüp düzenleyebilirsiniz !'}
          />

          <div>
            <button className='bg-main p-3 rounded-md text-white font-bold'>
              <a href={'/yemekler/toplu-fiyat-guncelle'}>Toplu Fiyat Güncelle</a>
            </button>
          </div>
        </div>

        <div className='mt-8 w-full  bg-white p-8 rounded-[20px]'>
          <div style={{width: '100%'}}>
            <DataGrid
              processRowUpdate={handleRowEdit}
              getRowId={(row) => row._id}
              paginationMode='server'
              loading={isLoading}
              rows={rows ? rows : []}
              columns={columns}
              sx={{
                border: 0,
                borderColor: '#D3D6E4',
                '.MuiDataGrid-columnHeader': {
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: 18,
                },
                '.MuiDataGrid-cell': {border: 0},
                '.MuiDataGrid-footerCell': {paddingBottom: 10},
                '.MuiDataGrid-footerContainer': {border: 0},
              }}
              localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{`${selectedFood?.name} Ürününü kaldırmak istiyor musunuz? `}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {selectedFood?.name} Ürününü kaldırmak istediğinize emin misiniz? Bu işlemin geri dönüşü yoktur. Ürününüz
            kalıcı olarak sistemden silinecektir.
          </DialogContentText>
        </DialogContent>
        <DialogActions className='mr-4'>
          <button
            onClick={() => setAlertOpen(false)}
            className=' text-[16px] font-semiBold text-black/60'
          >
            Vazgeç
          </button>
          <button
            onClick={handleRowDelete}
            className=' text-[16px] font-semiBold ml-2 text-main'
          >
            Kaldır
          </button>
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}

export default Page;
