import CardHeadingText from '../../../components/Text/CardHeadingText';
import HeadingText from '../../../components/Text/HeadingText';
import {FormControl, InputLabel, OutlinedInput, TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import AuthLayout from '../../../layout/AuthLayout';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import MainStore from '../../../store/MainStore';
import moment from 'moment';
import toast from 'react-hot-toast';

type TicketDataProps = {
  lastUpdated: string;
  _id: string;
  department: string;
  priority: string;
  title: string;
  created_at: string;
  data: TicketMessageProps[];
  restaurantID: string;
  shortID: string;
  status: string;
};

type TicketMessageProps = {
  _id?: string;
  sender: string;
  message: string;
  created_at: Date;
};

function Page() {
  const [appReady, setAppReady] = useState<boolean>(false);
  const [ticketData, setTicketData] = useState<TicketDataProps | null>(null);
  const [reply, setReply] = useState(false);
  const [message, setMessage] = useState('');
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      axios
        .get(`https://api.yemek.club/restaurant/api/ticketDetails/${id}`, {
          headers: {Authorization: `Bearer ${MainStore.token}`},
        })
        .then((res) => {
          const tempObject = {...res.data};
          tempObject.data.reverse();
          setTicketData(tempObject);
          setAppReady(true);
        })
        .catch((e) => {
          toast.error('Beklenmedik bir hata oluştu, lütfen daha sonra tekrar deneyiniz.');
          console.log(e);
        });
    }
  }, [id]);

  const replyTicket = async () => {
    axios
      .post(
        `https://api.yemek.club/restaurant/api/replyTicket/${id}`,
        {message},
        {headers: {Authorization: `Bearer ${MainStore.token}`}}
      )
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.success);
          if (ticketData) {
            const tempObject = {...ticketData};
            tempObject.data.reverse();
            tempObject.data.push({
              sender: MainStore.user.restaurantName,
              message,
              created_at: new Date(),
            });
            tempObject.data.reverse();
            setTicketData(tempObject);
            setReply(false);
            setMessage('');
          }
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((e) => {
        toast.error('Beklenmedik bir hata oluştu, lütfen daha sonra tekrar deneyiniz.');
      });
  };

  if (!appReady) {
    return null;
  }

  return (
    <AuthLayout>
      <div>
        <div className='mt-8 flex justify-between'>
          <div>
            <HeadingText
              title={'Destek Bildirimlerim'}
              subTitle={'Destek bildirimlerinizi kontrol edin!'}
            />
          </div>
          <div>
            <button
              onClick={() => setReply(!reply)}
              className='bg-main p-2 rounded-md text-white font-bold'
            >
              Yanıtla
            </button>
          </div>
        </div>

        <div className='mt-8 bg-white rounded-md p-4'>
          <div>
            <CardHeadingText
              title={`${ticketData?.shortID} - ${ticketData?.title}`}
              subTitle={' '}
            />
          </div>
          <div className='flex mt-8 w-full md:w-9/12 lg:w-5/12 2xl:w-3/12 justify-between text-center'>
            <div>
              <p className='font-semiBold text-[16px] text-black/90'>Son Güncelleme</p>
              <p className='font-semiBold text-[14px] text-black/80 text-center'>{`${moment(
                ticketData?.lastUpdated
              ).format('DD.MM.YYYY')} (${moment(ticketData?.lastUpdated).format('HH:MM')})`}</p>
            </div>

            <div>
              <p className='font-semiBold text-[16px] text-black/90'>Durum</p>
              <p className='font-semiBold text-[14px] text-black/80 text-center'>{ticketData?.status}</p>
            </div>

            <div>
              <p className='font-semiBold text-[16px] text-black/90'>Departman</p>
              <p className='font-semiBold text-[14px] text-black/80 text-center'>{ticketData?.department}</p>
            </div>

            <div className='hidden md:flex md:flex-col'>
              <p className='font-semiBold text-[16px] text-black/90'>Öncelik</p>
              <p className='font-semiBold text-[14px] text-black/80 text-center first-letter:uppercase'>
                {ticketData?.priority}
              </p>
            </div>
          </div>

          <div className='w-full flex flex-col gap-y-2 duration-300'>
            {reply && (
              <div className={`mt-8 border-[1px] rounded-lg w-full  lg:w-8/12 2xl:w-6/12 ml-auto flex flex-col`}>
                <div className='flex gap-x-2 items-center p-4'>
                  <div className='bg-main w-[35px] h-[35px] flex flex-col items-center justify-center rounded-md'>
                    <p className=' font-bold text-[30px] text-white'>R</p>
                  </div>
                  <div>
                    <p className='text-[17px] font-semiBold text-main'>{MainStore.user.restaurantName}</p>
                    <p className='text-[12px] text-black/70 font-medium'>{`${moment(new Date()).format(
                      'DD.MM.YYYY'
                    )} (${moment(new Date()).format('HH:MM')})`}</p>
                  </div>
                </div>

                <FormControl sx={{m: 1, width: '96%'}}>
                  <TextField
                    id='standard-multiline-flexible'
                    label='Cevabınz'
                    className='font-bold text-[17px]'
                    multiline={true}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormControl>
                <div className='m-2 mr-8 ml-auto'>
                  <button
                    onClick={replyTicket}
                    className='bg-main p-2 rounded-md text-white font-bold'
                  >
                    Yanıtla
                  </button>
                </div>
              </div>
            )}

            {ticketData?.data?.map((item: TicketMessageProps, index: number) => (
              <div
                key={index}
                className={`mt-8 border-[1px] rounded-lg p-4  lg:w-8/12 2xl:w-6/12 ${
                  item.sender !== 'Agent' && 'ml-auto'
                }`}
              >
                <div className='flex gap-x-2 items-center'>
                  {item.sender == 'Agent' ? (
                    <img
                      alt='agent'
                      src='https://i.pinimg.com/1200x/6d/40/b7/6d40b72ca7f6d4a4acee469a146e78e9.jpg'
                      className='w-[40px]'
                    />
                  ) : (
                    <div className='bg-main w-[35px] h-[35px] flex flex-col items-center justify-center rounded-md'>
                      <p className=' font-bold text-[30px] text-white'>R</p>
                    </div>
                  )}
                  <div>
                    <p className='text-[17px] font-semiBold text-main'>
                      {item.sender == 'Agent' ? 'Yemek.Club Destek' : item.sender}
                    </p>
                    <p className='text-[12px] text-black/70 font-medium'>{`${moment(item.created_at).format(
                      'DD.MM.YYYY'
                    )} (${moment(item.created_at).format('HH:MM')})`}</p>
                  </div>
                </div>
                <div className='mt-4'>
                  <p className='font-medium text-black/70'>{item.message}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default Page;
