import { PieChart } from '@mui/x-charts'
import React from 'react'

function OrderStatsChart() {
    return (
        <PieChart
            colors={['#3E4954', '#4477CE', '#2BC155', '#FF0100']}
            series={[
                {
                    data: [
                        { id: 0, value: 1, label: 'Bekleyen' },
                        { id: 1, value: 1, label: 'Yolda' },
                        { id: 2, value: 1, label: 'Teslim Edildi' },
                        { id: 3, value: 1, label: 'İptal Edilen' },
                    ],
                    paddingAngle: 4,
                    innerRadius: 40, //40 || 10
                    outerRadius: 100,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    cx: 100
                },
            ]}
            slotProps={{
                legend: {
                    hidden: true
                }
            }}

            width={400}
            height={200}

        />
    )
}

export default OrderStatsChart