export const HomeOrderStatsList = [
    {
        color: 'primary',
        title: 'Bekleyen (0%)',
        value: 0,
        stats: '0'
    },
    {
        color: 'secondary',
        title: 'Yolda (0%)',
        value: 0,
        stats: '0'
    },
    {
        color: 'success',
        title: 'Teslim Edildi (0%)',
        value: 0,
        stats: '0'
    },
    {
        color: 'error',
        title: 'İptal Edildi (0 %)',
        value: 0,
        stats: '0'
    },
]