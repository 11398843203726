import React from 'react'

interface PopularMenuProps {
    name: string;
    foodImage: string;
    totalSale: number;
}

function PopularMenuItem({ name, foodImage, totalSale }: PopularMenuProps) {
    return (
        <div className='flex'>
            <div>
                <img src={foodImage} className='w-[50px] h-[50px] rounded-lg' />
            </div>
            <div className=' self-center pl-2'>
                <div>
                    <p className=' text-lg font-bold leading-4'>{name}</p>
                </div>
                <div>
                    <p className='text-black/50 text-[14px]'>Toplam Sipariş : {totalSale}</p>
                </div>
            </div>
        </div>
    )
}

export default PopularMenuItem